<template>
  <div class="card mb-3">
    <div class="card-header">
      <h5 class="mb-0">
        {{ formatWeekDay(disponibilita.giornoSettimana) }}
        {{ formatLocalDate(disponibilita.dataInizio) }}
      </h5>
    </div>
    <div class="card-body">
      <p><strong>Orario:</strong> {{ formatTime(disponibilita.oraInizio) }} -
        {{ formatTime(disponibilita.oraFine) }}</p>
      <p><strong>Max Partecipanti:</strong>
        {{ formatMaxPartecipanti(disponibilita.maxPartecipantiGruppo) }}</p>
      <p><strong>Ricorrente:</strong> {{ formatBoolean(disponibilita.ricorrente) }}</p>
      <p style="margin: 0; padding: 0;" v-if="disponibilita.ricorrente"><strong>Data Fine
        Ricorrenze:</strong></p>
      <p style="margin: 0; padding: 0;" v-if="disponibilita.ricorrente">
        {{ formatLocalDate(disponibilita.dataFine) }}</p>
      <div class="text-end">
        <button type="button" class="btn btn-primary btn-sm" @click="$emit('show-modal', disponibilita)">
          Gestisci
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisponibilitaCard',
  props: {
    disponibilita: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatWeekDay(value) {
      const days = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
      return days[value] || '';
    },
    formatLocalDate(localDate) {
      const date = new Date(localDate);
      return date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' });
    },
    formatTime(timeString) {
      return timeString.slice(0, 5);
    },
    formatMaxPartecipanti(value) {
      return value === null || value === undefined ? "Nessun limite" : value;
    },
    formatBoolean(value) {
      return value ? 'Si' : 'No';
    }
  }
}
</script>