<!-- DisponibilitaAccordion.vue -->
<template>
  <div>
    <div v-if="disponibilitaGrouped && Object.keys(disponibilitaGrouped).length">
      <div id="accordion">
        <div v-for="(fasce, data) in disponibilitaGrouped" :key="data">
          <div class="card" v-if="Array.isArray(fasce) && fasce.length && data">
            <AccordionHeader :data="data" :fasce="fasce" :giornoSettimana="giornoSettimana" />
            <AccordionBody :data="data" :fasce="fasce" @prenota="$emit('prenota', $event)" :current-user-id="currentUserId" />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading" class="text-center">
      <div class="spinner-border text-muted"></div>
    </div>
    <div v-else>
      <p>Non sono presenti disponibilità per questa materia.</p>
    </div>
  </div>
</template>

<script>
import AccordionHeader from './AccordionHeader.vue';
import AccordionBody from './AccordionBody.vue';

export default {
  name: 'DisponibilitaAccordion',
  components: {
    AccordionHeader,
    AccordionBody
  },
  props: {
    disponibilitaGrouped: Object,
    giornoSettimana: Array,
    isLoading: Boolean,
    currentUserId: Number
  },
  emits: ['prenota']
}
</script>
