<template>
  <div id="body_div">
    <div id="app">
      <LoginPage v-if="!currentUser.id" @login-success="handleLoginSuccess"/>
      <VerifyIdentity v-else-if="showVerify" :currentUser="currentUser"/>
      <UserDashboard v-else :currentUser="currentUser"/>
    </div>
    <GlobalLoading />
  </div>
</template>

<script>
import LoginPage from "@/components/LoginPage.vue";
import UserDashboard from "@/components/UserDashboard.vue";
import GlobalLoading from "@/components/GlobalLoading.vue";
import VerifyIdentity from "@/components/VerifyIdentity.vue";

export default {
  name: 'App',
  components: {
    GlobalLoading,
    LoginPage,
    UserDashboard,
    VerifyIdentity
  },
  data() {
    return {
      currentUser: {
        id: '',
        role: ''
      },
      showVerify: false
    }
  },
  methods: {
    handleLoginSuccess(user) {
      this.currentUser = parseInt(user);
      this.showVerify = false;
    },
    checkURLParams() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('loggedIn')) {
        const loggedInId = urlParams.get('loggedIn');
        const role = urlParams.get('role');
        if(!sessionStorage.getItem("loggedIn")){ //TODO temporary fix for CORS
          sessionStorage.setItem("loggedIn", loggedInId);
          window.location.reload();
          return
        }
        this.currentUser.id = parseInt(loggedInId);
        this.currentUser.role = role;
        this.showVerify = false;
      }
      else if (urlParams.has('verify')) {
        if(!sessionStorage.getItem("verifying")){ //TODO temporary fix for CORS
          sessionStorage.setItem("verifying", urlParams.get('verify'));
          window.location.reload();
          return
        }
        this.currentUser.id = urlParams.get('verify');
        this.showVerify = true;
      }
      else{ //TODO quando poi si sarà in production, inizializzare come quando ci sono gli url parameters (se c'è loggedIn valorizzato, insieme a role)
        sessionStorage.removeItem("loggedIn");
      }
    }
  },
  mounted() {
    this.checkURLParams();
  }
}
</script>

<style>
body {
  background-image: url('./images/wa-prenotazione.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.custom-btn {
  min-width: 70px; /* Imposta una larghezza minima per uniformare le dimensioni */
  height: 35px; /* Imposta un'altezza fissa per uniformare le dimensioni */
  line-height: 1.5; /* Regola l'altezza della linea per l'allineamento del testo */
  text-align: center; /* Allinea il testo al centro */
  margin-right: 3px;
}

.clickable {
  cursor: pointer; /* Cambia il cursore per indicare che è cliccabile */
}

.underline {
  position: relative;
}

.underline::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px; /* Distanza dal testo */
  height: 1px; /* Spessore della sottolineatura */
  background-color: #000000; /* Colore della sottolineatura */
  transition: all 0.3s ease; /* Transizione per un effetto più fluido */
  opacity: 0; /* Inizialmente invisibile */
}

.underline.active::after {
  opacity: 1; /* Rendi visibile la sottolineatura quando attiva */
}
</style>
