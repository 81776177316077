<template>
  <div>
    <div class="mt-3 mb-3 d-flex justify-content-between align-items-center" v-if="totalPages > 1">
      <button class="btn btn-sm btn-dark" @click="$emit('prev-page')" :disabled="currentPage === 1">Indietro</button>
      <button class="btn btn-sm btn-dark" @click="$emit('next-page')" :disabled="currentPage === totalPages">Avanti</button>
    </div>
    <h6 class="text-center">{{ currentWeekRange }}</h6>
    <ul class="list-group mt-3">
      <li v-for="(date, index) in paginatedDates" :key="date + index"
          class="list-group-item d-flex justify-content-between align-items-center"
          @mouseenter="setCursorPointer" @mouseleave="setCursorDefault"
          :class="{ 'active': selectedDate === date }"
          @click="$emit('select-date', date)">
        {{ getDayName(date) }} - {{ formatLocalDate(date) }}
        <div class="text-end">({{ countDisponibilitaPerData(date) }})</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DateSelector',
  props: {
    currentPage: Number,
    totalPages: Number,
    currentWeekRange: String,
    paginatedDates: Array,
    selectedDate: String,
    disponibilitaList: Array
  },
  methods: {
    getDayName(dateString) {
      const daysOfWeek = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      return daysOfWeek[dayIndex];
    },
    formatLocalDate(localDate) {
      const date = new Date(localDate);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    countDisponibilitaPerData(date) {
      return this.disponibilitaList.filter(d => d.dataInizio === date).length;
    },
    setCursorPointer(event) {
      event.target.style.cursor = 'pointer';
      event.target.style.backgroundColor = '#0d6efd';
      event.target.style.color = 'white';
    },
    setCursorDefault(event) {
      event.target.style.cursor = 'default';
      event.target.style.backgroundColor = '';
      event.target.style.color = '';
    }
  }
}
</script>