<template>
  <div class="row">
    <div v-for="disponibilita in filteredDisponibilita" :key="disponibilita.id" class="col-md-6">
      <DisponibilitaCard
          :disponibilita="disponibilita"
          @show-modal="$emit('show-modal', disponibilita)"
      />
    </div>
    <div v-if="filteredDisponibilita.length === 0" class="col-12 text-center">
      <p class="text-muted">Selezionare una data per mostrare le disponibilità.</p>
    </div>
  </div>
</template>

<script>
import DisponibilitaCard from './DisponibilitaCard.vue';

export default {
  name: 'DisponibilitaList',
  components: {
    DisponibilitaCard
  },
  props: {
    filteredDisponibilita: {
      type: Array,
      required: true
    }
  }
}
</script>