// In your axios interceptors file (e.g., axios-config.js)

import axios from 'axios'
import store from '@/store'

let requestCount = 0

axios.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        if (requestCount === 0) {
            store.dispatch('setLoading', true)
        }
        requestCount++
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    (response) => {
        requestCount--
        if (requestCount === 0) {
            store.dispatch('setLoading', false)
        }
        return response
    },
    (error) => {
        requestCount--
        if (requestCount === 0) {
            store.dispatch('setLoading', false)
        }

        // Check for authentication error (adjust the condition based on your API's error response)
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            store.dispatch('setAuthError', true)
            alert('La tua sessione è scaduta. Effettua nuovamente il login.')
            sessionStorage.removeItem("loggedIn");
            location.href="/"
        }

        return Promise.reject(error)
    }
)
