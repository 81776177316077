<template>
  <div class="mt-4">
    <h3 class="mb-3">Gestione Disponibilità Materie</h3>
    <button type="button" class="btn btn-primary mb-3" @click="toggleAddSubject">
      {{ showAddSubject ? 'Chiudi' : 'Aggiungi Materia' }}
    </button>
    <div v-if="showAddSubject" class="mb-3">
      <SubjectList :items="availableSubjects" @select="addSubject"/>
    </div>
    <ul class="list-group">
      <li v-for="subject in subjects" :key="subject.nome" class="list-group-item d-flex justify-content-between align-items-center">
        {{ subject.nome }}
        <button type="button" class="btn btn-danger btn-sm" @click="removeSubject(subject.nome)">Rimuovi</button>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import config from "@/utils/config";
import SubjectList from "@/components/SubjectList.vue";

export default {
  components: { SubjectList },
  props: {
    currentUser: Object
  },
  data() {
    return {
      subjects: [],
      showAddSubject: false,
      allSubjects: [],
      availableSubjects: []
    };
  },
  methods: {
    fetchAllSubjects() {
      axios.get(`${config.baseUrl}/materia`).then(response => {
        this.allSubjects = response.data;
        this.updateAvailableSubjects();
      });
    },
    toggleAddSubject() {
      this.showAddSubject = !this.showAddSubject;
      if (this.showAddSubject && this.allSubjects.length === 0) {
        this.fetchAllSubjects();
      }
    },
    addSubject(subject) {
      axios.post(`${config.baseUrl}/utenteInsegnaMateria/${this.currentUser.id}/${subject.nome}`).then(() => {
        this.subjects.push(subject);
        this.updateAvailableSubjects();
      });
    },
    removeSubject(subject) {
      axios.delete(`${config.baseUrl}/utenteInsegnaMateria/${this.currentUser.id}/${subject}`).then(() => {
        this.subjects = this.subjects.filter(s => s.nome !== subject);
        this.updateAvailableSubjects();
      });
    },
    getSubjects() {
      axios.get(`${config.baseUrl}/utenteInsegnaMateria/byUser/${this.currentUser.id}`).then(response => {
        this.subjects = response.data;
        this.updateAvailableSubjects();
      });
    },
    updateAvailableSubjects() {
      const subjectNames = new Set(this.subjects.map(s => s.nome));
      this.availableSubjects = this.allSubjects.filter(subject => !subjectNames.has(subject.nome));
    }
  },
  mounted() {
    this.getSubjects();
  }
};
</script>

<style scoped>
.list-group-item-action:hover {
  cursor: pointer;
  background-color: #f8f9fa;
}
</style>
