<template>
  <div class="gestione-materie">
    <h4>Gestione Materie</h4>

    <!-- Dichiarazione voti -->
    <div class="card mb-3">
      <div class="card-header">
        <h5>I miei voti</h5>
      </div>
      <div class="card-body">
        <table class="table" v-if="votiDichiarati.length > 0">
          <thead>
          <tr>
            <th>Materia</th>
            <th>Voto</th>
            <th>Gestione</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="voto in votiDichiarati" :key="voto.id">
            <td>{{ voto.materia.nome }}</td>
            <td>{{ voto.voto }}</td>
            <td>
              <button class="btn btn-sm btn-danger" @click="deleteVoto(voto.id)">
                Cancella
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <ul class="list-group" v-else>
          <li class="list-group-item" >
            Non hai indicato nessun voto.
          </li>
          <br>
        </ul>


        <h5>Aggiungine uno nuovo:</h5>
        <!-- Form nuovo voto -->
        <form @submit.prevent="addVoto" class="row g-3">
          <div v-show="!this.nuovoVoto.materia">
            <SubjectList
                :items="materieDisponibiliVoti"
                @select="selectMateriaVoto"
            />
          </div>
          <div class="col-md-5" v-show="this.nuovoVoto.materia" @click="clearMateriaVoto">
            {{ this.nuovoVoto.materia ? this.nuovoVoto.materia.nome : '' }}
          </div>
          <div class="col-md-5" v-show="this.nuovoVoto.materia">
            <input type="number" v-model="nuovoVoto.voto" class="form-control" id="votoInput"
                   min="1" max="10" required placeholder="Voto">
          </div>
          <div class="col-md-2" v-show="this.nuovoVoto.materia">
            <button type="submit" class="btn btn-primary"
                    :disabled="!nuovoVoto.materia">Aggiungi</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Richieste di aiuto -->
    <div class="card">
      <div class="card-header">
        <h5>Materie in cui ho bisogno di aiuto</h5>
      </div>
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item" v-if="materieRecupero.length === 0">
            Non hai indicato nessuna materia da recuperare.
          </li>
          <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center hover-danger"
              v-for="recupero in materieRecupero"
              :key="recupero.materiaNome"
              @click="removeRecuperoMateria(recupero.materiaNome)"
              role="button">
            {{ recupero.materiaNome }}
            <i class="bi bi-x-circle"></i>
          </li>
        </ul>
        <br>
        <h5>Aggiungine una nuova:</h5>
        <!-- Form nuova richiesta -->
        <form @submit.prevent="addRecuperoMateria" class="row g-3">
          <SubjectList
              :items="materieDisponibiliRecupero"
              @select="selectMateriaRecupero"
          />
        </form>
      </div>
    </div>
  </div>
</template>
<style scoped>
.hover-danger:hover {
  background-color: #dc3545;
  color: white;
  cursor: pointer;
}

.hover-danger:hover i {
  color: white;
}

.bi-x-circle {
  color: #dc3545;
}
</style>
<script>
import axios from 'axios';
import config from "@/utils/config";
import SubjectList from './SubjectList.vue';

export default {
  name: 'GestioneMaterie',
  components: {
    SubjectList
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      materie: [],
      votiDichiarati: [],
      materieRecupero: [],
      nuovoVoto: {
        materia: null,
        voto: null
      },
      nuovaRichiestaMateria: ''
    }
  },
  computed: {
    materieDisponibiliVoti() {
      // Filtra le materie che non hanno già un voto dichiarato
      return this.materie.filter(materia =>
          !this.votiDichiarati.some(voto =>
              voto.materia.nome === materia.nome
          )
      );
    },
    materieDisponibiliRecupero() {
      // Filtra le materie per cui non è già stata fatta richiesta di recupero
      return this.materie.filter(materia =>
          !this.materieRecupero.some(recupero =>
              recupero.materiaNome === materia.nome
          )
      );
    }
  },
  async created() {
    await this.loadData();
  },
  methods: {
    clearMateriaVoto(){
      this.nuovoVoto.materia = null
    },
    async loadData() {
      try {
        const [materieRes, votiRes, recuperiRes] = await Promise.all([
          axios.get(`${config.baseUrl}/materia`),
          axios.get(`${config.baseUrl}/voto-dichiarato/utente/${this.currentUser.id}`),
          axios.get(`${config.baseUrl}/utenteRecuperaMateria/utente/${this.currentUser.id}`)
        ]);

        this.materie = materieRes.data;
        this.votiDichiarati = votiRes.data;
        this.materieRecupero = recuperiRes.data;
      } catch (error) {
        console.error('Errore nel caricamento dei dati:', error);
      }
    },
    selectMateriaVoto(materia) {
      this.nuovoVoto.materia = materia;
    },
    selectMateriaRecupero(materia) {
      this.nuovaRichiestaMateria = materia.nome;
      this.addRecuperoMateria()
      document.getElementById("votoInput").focus()
    },
    async addVoto() {
      if (!this.nuovoVoto.materia || !this.nuovoVoto.voto) return;

      try {
        await axios.post(`${config.baseUrl}/voto-dichiarato`, {
          voto: this.nuovoVoto.voto,
          utente: { id: this.currentUser.id },
          materia: this.nuovoVoto.materia
        });
        await this.loadData();
        this.nuovoVoto = { materia: null, voto: null };
      } catch (error) {
        console.error('Errore nell\'aggiunta del voto:', error);
      }
    },
    async deleteVoto(votoId) {
      try {
        await axios.delete(`${config.baseUrl}/voto-dichiarato/${votoId}`);
        await this.loadData();
      } catch (error) {
        console.error('Errore nella cancellazione del voto:', error);
      }
    },
    async addRecuperoMateria() {
      if (!this.nuovaRichiestaMateria) return;

      try {
        await axios.post(`${config.baseUrl}/utenteRecuperaMateria`, {
          utenteId: this.currentUser.id,
          materiaNome: this.nuovaRichiestaMateria
        });
        await this.loadData();
        this.nuovaRichiestaMateria = '';
      } catch (error) {
        console.error('Errore nell\'aggiunta della richiesta di recupero:', error);
      }
    },
    async removeRecuperoMateria(materiaNome) {
      try {
        await axios.delete(`${config.baseUrl}/utenteRecuperaMateria/${this.currentUser.id}/${materiaNome}`);
        await this.loadData();
      } catch (error) {
        console.error('Errore nella rimozione della richiesta di recupero:', error);
      }
    }
  }
}
</script>