<template>
  <div>
    <button v-if="currentUser.role==='buddy'" class="btn mb-3 btn-warning btn-sm" @click="$emit('change-section')">
      ← Cambia sezione
    </button>

    <NavigationTabs
        v-model="activeTabId"
        :tabs="navigationTabs"
        :showActionButton="showDispSection"
        :actionButtonConfig="{
          label: '+ Crea nuova',
          toggle: 'modal',
          target: '#creaNuovaDisp'
        }"
        @tab-click="handleTabClick"
    />

    <hr>

    <GestioneDisponibilita
        v-if="showDispSection"
        :currentUser="currentUser"
    />
    <SubjectAvailability
        v-if="showSubjSection"
        :currentUser="currentUser"
    />
    <div v-if="showPrenSection" class="mb-3">
      <ListaPrenotazioni
          :currentUser="currentUser"
          :user-type="'tutor'"
      />
    </div>
    <ListaInterazioni v-if="showInterazioniSection" :current-user="currentUser"/>
    <UserProfile
        v-if="showProfileSection"
        :userId="currentUser.id"
        :currentUserId="currentUser.id"
    />
  </div>
</template>

<script>
import GestioneDisponibilita from "@/components/GestioneDisponibilita.vue";
import ListaPrenotazioni from "@/components/ListaPrenotazioni.vue";
import SubjectAvailability from "@/components/SubjectAvailability.vue";
import NavigationTabs from '@/components/NavigationTabs.vue';
import ListaInterazioni from "@/components/ListaInterazioni.vue";
import UserProfile from "@/components/UserProfile.vue";

export default {
  name: 'TutorDashboard',
  components: {
    UserProfile,
    GestioneDisponibilita,
    SubjectAvailability,
    ListaPrenotazioni,
    NavigationTabs,
    ListaInterazioni
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTabId: 'sezione-disponibilita',
      navigationTabs: [
        {
          id: 'sezione-disponibilita',
          label: 'Disponibilità',
          event: 'show-disponibilita'
        },
        {
          id: 'sezione-subject-availability',
          label: 'Materie',
          event: 'show-subject-availability'
        },
        {
          id: 'sezione-prenotazioni',
          label: 'Prenotazioni',
          event: 'show-prenotazioni'
        },
        {
          id: 'sezione-interazioni',
          label: 'Interazioni',
          event: 'show-interazioni'
        },
        {
          id: 'sezione-profilo',
          label: 'Profilo',
          event: 'show-profile'
        }
      ]
    }
  },
  computed: {
    showDispSection() {
      return this.activeTabId === 'sezione-disponibilita';
    },
    showSubjSection() {
      return this.activeTabId === 'sezione-subject-availability';
    },
    showPrenSection() {
      return this.activeTabId === 'sezione-prenotazioni';
    },
    showInterazioniSection() {
      return this.activeTabId === 'sezione-interazioni';
    },
    showProfileSection() {
      return this.activeTabId === 'sezione-profilo';
    }
  },
  methods: {
    handleTabClick(tab) {
      this.$emit(tab.event);

      switch (tab.event) {
        case 'show-disponibilita':
          this.emitSectionChange({
            disp: true,
            subj: false,
            pren: false,
            interazioni: false,
            profile: false
          });
          break;
        case 'show-subject-availability':
          this.emitSectionChange({
            disp: false,
            subj: true,
            pren: false,
            interazioni: false,
            profile: false
          });
          break;
        case 'show-prenotazioni':
          this.emitSectionChange({
            disp: false,
            subj: false,
            pren: true,
            interazioni: false,
            profile: false
          });
          break;
        case 'show-interazioni':
          this.emitSectionChange({
            disp: false,
            subj: false,
            pren: false,
            interazioni: true,
            profile: false
          });
          break;
        case 'show-profile':
          this.emitSectionChange({
            disp: false,
            subj: false,
            pren: false,
            interazioni: false,
            profile: true
          });
          break;
      }
    },
    emitSectionChange({disp, subj, pren, interazioni, profile}) {
      this.$emit('update:showDispSection', disp);
      this.$emit('update:showSubjSection', subj);
      this.$emit('update:showPrenSection', pren);
      this.$emit('update:showInterazioniSection', interazioni);
      this.$emit('update:showProfileSection', profile);
    }
  }
}
</script>
