<template>
  <DatePicker
      :value="value"
      @input="handleInput"
      multiple
      format="DD-MM-YYYY"
      placeholder="Seleziona le date"
      :disabled-date="combinedDisabledDate"
  />
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'CustomDatePicker',
  components: {
    DatePicker
  },
  props: {
    value: Array,
    disabledDate: Function,
    startDate: {
      type: Date,
      required: true
    }
  },
  computed: {
    combinedDisabledDate() {
      return (date) => {
        const oneYearLater = new Date(this.startDate);
        oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

        return this.disabledDate(date) || date > oneYearLater;
      };
    }
  },
  methods: {
    handleInput(dates) {
      const oneYearLater = new Date(this.startDate);
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

      const filteredDates = dates.filter(date => date <= oneYearLater);
      this.$emit('input', filteredDates);
    }
  }
}
</script>