import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoading: false
    },
    mutations: {
        SET_LOADING(state, value) {
            state.isLoading = value
        }
    },
    actions: {
        setLoading({ commit }, value) {
            if (value) {
                commit('SET_LOADING', true)
            } else {
                setTimeout(() => {
                    commit('SET_LOADING', false)
                }, 300) // Adjust this delay as needed
            }
        }
    }
})
