<template>
  <div class="gestione-gruppi">
    <!-- Alert container -->
    <div class="alert-container mb-3">
      <div v-if="alert" :class="`alert alert-${alert.type} alert-dismissible fade show`" role="alert">
        {{ alert.message }}
        <button type="button" class="btn-close" @click="closeAlert" aria-label="Close"></button>
      </div>
    </div>

    <!-- Join Group Section -->
    <div class="mb-4">
      <h3>Unisciti a un Gruppo</h3>
      <form @submit.prevent="joinGroup" class="mb-3">
        <div class="input-group">
          <input
              v-model="inviteCode"
              class="form-control"
              placeholder="Inserisci il codice di invito"
              required
          >
          <button type="submit" class="btn btn-success">
            Unisciti
          </button>
        </div>
      </form>
    </div>

    <!-- Create Group Section -->
    <div class="mb-4">
      <h3>Crea Nuovo Gruppo</h3>
      <form @submit.prevent="createGroup" class="mb-3">
        <div class="form-group">
          <input v-model="newGroup.nome" class="form-control mb-2" placeholder="Nome gruppo" required>
          <textarea v-model="newGroup.descrizione" class="form-control mb-2" placeholder="Descrizione" required></textarea>
          <button type="submit" class="btn btn-primary">Crea Gruppo</button>
        </div>
      </form>
    </div>

    <!-- My Groups Section -->
    <div class="my-groups mb-4">
      <h3>I Miei Gruppi</h3>
      <div v-if="myGroups.length === 0" class="alert alert-info">
        Non hai ancora creato gruppi
      </div>
      <div v-else class="row">
        <div v-for="group in myGroups" :key="group.id" class="col-md-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">{{ group.nome }}</h5>
              <p class="card-text">{{ group.descrizione }}</p>
              <div class="mb-2">
                <strong>Link di invito:</strong>
                <div class="input-group">
                  <input type="text" class="form-control" :value="group.inviteLink" readonly>
                  <button class="btn btn-outline-secondary" @click="copyInviteLink(group.inviteLink)">
                    Copia
                  </button>
                </div>
              </div>
              <div class="mb-2">
                <strong>Partecipanti:</strong>
                <ul class="list-unstyled">
                  <li v-for="participant in group.partecipanti" :key="participant.id">
                    {{ participant.nome }} {{ participant.cognome }}
                  </li>
                </ul>
              </div>
              <button class="btn btn-danger" @click="showDeleteModal(group.id)">
                Elimina Gruppo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Participating Groups Section -->
    <div class="participating-groups">
      <h3>Gruppi a cui Partecipi</h3>
      <div v-if="participatingGroups.length === 0" class="alert alert-info">
        Non partecipi a nessun gruppo
      </div>
      <div v-else class="row">
        <div v-for="group in participatingGroups" :key="group.id" class="col-md-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">{{ group.nome }}</h5>
              <p class="card-text">{{ group.descrizione }}</p>
              <div class="mb-2">
                <strong>Partecipanti:</strong>
                <ul class="list-unstyled">
                  <li v-for="participant in group.partecipanti" :key="participant.id">
                    {{ participant.nome }} {{ participant.cognome }}
                  </li>
                </ul>
              </div>
              <button class="btn btn-warning" @click="showLeaveModal(group.id)">
                Lascia Gruppo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Group Modal -->
    <div class="modal fade" id="deleteGroupModal" tabindex="-1" aria-labelledby="deleteGroupModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteGroupModalLabel">Conferma eliminazione</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Sei sicuro di voler eliminare questo gruppo?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annulla</button>
            <button type="button" class="btn btn-danger" @click="confirmDeleteGroup">Elimina</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Leave Group Modal -->
    <div class="modal fade" id="leaveGroupModal" tabindex="-1" aria-labelledby="leaveGroupModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="leaveGroupModalLabel">Conferma uscita</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Sei sicuro di voler lasciare questo gruppo?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annulla</button>
            <button type="button" class="btn btn-warning" @click="confirmLeaveGroup">Lascia</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from "@/utils/config";
import { Modal } from 'bootstrap';

export default {
  name: 'GestioneGruppi',
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      inviteCode: '',
      newGroup: {
        nome: '',
        descrizione: ''
      },
      myGroups: [],
      participatingGroups: [],
      alert: null,
      deleteModal: null,
      leaveModal: null,
      selectedGroupId: null
    }
  },
  created() {
    this.fetchMyGroups();
    this.fetchParticipatingGroups();
  },
  mounted() {
    this.deleteModal = new Modal(document.getElementById('deleteGroupModal'));
    this.leaveModal = new Modal(document.getElementById('leaveGroupModal'));
  },
  methods: {
    showAlert(type, message) {
      this.alert = { type, message };
    },
    closeAlert() {
      this.alert = null;
    },
    async joinGroup() {
      try {
        const response = await axios.get(config.baseUrl+'/utenteGruppo/join', {
          params: {
            utenteId: this.currentUser.id,
            inviteLink: this.inviteCode
          }
        });

        if (response.status === 200) {
          this.showAlert('success', 'Ti sei unito al gruppo con successo!');
          this.inviteCode = '';
          await this.fetchParticipatingGroups();
        }
      } catch (error) {
        if (error.response?.status === 403) {
          this.showAlert('danger', 'Codice di invito non valido o sei già membro di questo gruppo');
        } else {
          this.showAlert('danger', 'Errore durante l\'accesso al gruppo');
        }
      }
    },
    async createGroup() {
      try {
        const response = await axios.post(config.baseUrl+'/gruppo', {
          ...this.newGroup,
          creator: this.currentUser
        }, {
          params: {
            creatorId: this.currentUser.id
          }
        });
        this.myGroups.push(response.data);
        this.newGroup.nome = '';
        this.newGroup.descrizione = '';
        this.showAlert('success', 'Gruppo creato con successo!');
      } catch (error) {
        this.showAlert('danger', 'Errore durante la creazione del gruppo');
      }
    },
    async fetchMyGroups() {
      try {
        const response = await axios.get(`${config.baseUrl}/gruppo/myGroups/${this.currentUser.id}`);
        this.myGroups = response.data;
      } catch (error) {
        this.showAlert('danger', 'Errore durante il recupero dei gruppi');
      }
    },
    async fetchParticipatingGroups() {
      try {
        const response = await axios.get(`${config.baseUrl}/gruppo/participating/${this.currentUser.id}`);
        this.participatingGroups = response.data;
      } catch (error) {
        this.showAlert('danger', 'Errore durante il recupero dei gruppi');
      }
    },
    showDeleteModal(groupId) {
      this.selectedGroupId = groupId;
      this.deleteModal.show();
    },
    async confirmDeleteGroup() {
      try {
        await axios.delete(`${config.baseUrl}/gruppo/${this.selectedGroupId}`);
        this.myGroups = this.myGroups.filter(g => g.id !== this.selectedGroupId);
        this.showAlert('success', 'Gruppo eliminato con successo');
        this.deleteModal.hide();
      } catch (error) {
        this.showAlert('danger', 'Errore durante l\'eliminazione del gruppo');
      }
    },
    showLeaveModal(groupId) {
      this.selectedGroupId = groupId;
      this.leaveModal.show();
    },
    async confirmLeaveGroup() {
      try {
        await axios.delete(`${config.baseUrl}/utenteGruppo/leave`, {
          params: {
            utenteId: this.currentUser.id,
            gruppoId: this.selectedGroupId
          }
        });
        this.participatingGroups = this.participatingGroups.filter(g => g.id !== this.selectedGroupId);
        this.showAlert('success', 'Hai lasciato il gruppo con successo');
        this.leaveModal.hide();
      } catch (error) {
        this.showAlert('danger', 'Errore durante l\'uscita dal gruppo');
      }
    },
    copyInviteLink(link) {
      navigator.clipboard.writeText(link);
      this.showAlert('success', 'Link copiato negli appunti');
    }
  }
}
</script>

<style scoped>
.gestione-gruppi {
  padding: 20px;
}

.input-group {
  max-width: 500px;
}

.alert-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1050;
}
</style>
