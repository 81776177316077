<!-- MateriaTitolo.vue -->
<template>
  <div class="mb-3">
    <h5 class="d-inline">Disponibilità per la materia: </h5>
    <h5 class="d-inline" style="color: #007bff">{{ nome }}</h5>
  </div>
</template>

<script>
export default {
  name: 'MateriaTitolo',
  props: {
    nome: String
  }
}
</script>
