<template>
  <div class="modal fade" id="eliminazioneFuture" tabindex="-1" aria-labelledby="eliminazioneLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="gestisciLabel">Conferma eliminazione</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal-delete"></button>
        </div>
        <div class="modal-body">
          <strong>Attenzione!</strong><br>
          <span v-if="selectedDisponibilita && selectedDisponibilita.ricorrente">
            Eliminare anche le occorrenze future di questa disponibilità?
          </span>
          <span v-else>
            Sei sicuro di voler eliminare questa disponibilità?
          </span>
        </div>
        <div class="modal-footer">
          <div id="loadingSpinnerDelete" style="display: none" class="spinner-border text-muted"></div>
          <template v-if="selectedDisponibilita && selectedDisponibilita.ricorrente">
            <button @click="deleteDisponibilitaRicorrente" class="btn btn-danger custom-btn" id="deleteDispButton">Si</button>
            <button @click="deleteDisponibilita" class="btn btn-secondary custom-btn" id="deleteDispButton">No</button>
          </template>
          <template v-else>
            <button @click="deleteDisponibilita" class="btn btn-danger custom-btn" id="deleteDispButton">Si</button>
            <button @click="closeModal" class="btn btn-secondary custom-btn" id="cancelDeleteButton">No</button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalEliminazione',
  props: {
    selectedDisponibilita: Object
  },
  methods: {
    deleteDisponibilitaRicorrente() {
      this.$emit('delete-disponibilita-ricorrente', this.selectedDisponibilita);
      this.closeModal();
    },
    deleteDisponibilita() {
      this.$emit('delete-disponibilita', this.selectedDisponibilita);
      this.closeModal();
    },
    closeModal() {
      const modal = document.getElementById('eliminazioneFuture');
      if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
          backdrop.remove();
        }
      }
    }
  }
}
</script>