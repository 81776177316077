<template>
  <div>
    <DisponibilitaManager
        v-if="disponibilitaList.length"
        :disponibilitaList="disponibilitaList"
        @show-modal="showModal"
    />

    <ModalGestisciModifica
        :selectedDisponibilita="selectedDisponibilita"
        :parentDisponibilita="parentDisponibilita"
        :childDisponibilita="childDisponibilita"
        :shownItems="shownItems"
        @show-more="showMore"
        @modify-disponibilita="modificaDisponibilita"
        @modify-disponibilita-ricorrente="modificaDisponibilitaRicorrente"
        @show-modal-delete="showModalDelete"
    />

    <ModalEliminazione
        :selectedDisponibilita="selectedDisponibilita"
        @delete-disponibilita="eliminaDisponibilita"
        @delete-disponibilita-ricorrente="eliminaDisponibilitaRicorrente"
    />

    <ModalCreaNuova
        :disponibilita="disponibilita"
        :todayDate="todayDate"
        :selectedDatesCustomFrequency="selectedDatesCustomFrequency"
        @save-disponibilita="saveDisponibilita"
        @toggle-ricorrente="toggleRicorrente"
        :utenteId="disponibilita.utenteId"
    />

    <div v-if="!disponibilitaList.length" style="margin-bottom: 20%;">
      <p class="d-inline"><b>Al momento non sono presenti disponibilità.</b></p>
      <button class="btn btn-secondary shadow-none btn-sm d-inline ms-2" data-bs-toggle="modal"
              data-bs-target="#creaNuovaDisp">
        Creane una!
      </button>
    </div>
  </div>
</template>

<script>
import {Modal} from 'bootstrap';
import axios from 'axios';
import DisponibilitaManager from './DisponibilitaManager.vue';
import ModalGestisciModifica from './ModalGestisciModifica.vue';
import ModalEliminazione from './ModalEliminazione.vue';
import ModalCreaNuova from './ModalCreaNuova.vue';
import config from "@/utils/config";

export default {
  name: 'GestioneDisponibilita',
  components: {
    DisponibilitaManager,
    ModalGestisciModifica,
    ModalEliminazione,
    ModalCreaNuova
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      selectedDatesCustomFrequency: [],
      disponibilitaList: [],
      disponibilita: {
        id: '',
        utenteId: -1,
        oraInizio: '',
        oraFine: '',
        dataInizio: '',
        dataFine: '',
        ricorrente: false,
        frequenza: '',
        giornoSettimana: '',
        maxPartecipantiGruppo: '',
        parent: ''
      },
      selectedDisponibilita: null,
      parentDisponibilita: null,
      childDisponibilita: [],
      shownItems: 3,
    }
  },
  mounted() {
    this.disponibilita.utenteId = this.currentUser.id;
    this.caricaDisponibilita(this.disponibilita.utenteId);
  },
  computed: {
    todayDate() {
      return new Date().toISOString().split('T')[0];
    },
  },
  methods: {
    showMore() {
      this.shownItems += 3;
    },
    caricaDisponibilita(utenteId) {
      axios.get(`${config.baseUrl}/disponibilita/utente/${encodeURIComponent(utenteId)}`)
          .then(response => {
            this.disponibilitaList = response.data;
          })
          .catch(error => {
            console.error('Errore durante il caricamento delle disponibilità:', error);
          });
    },
    eliminaDisponibilitaRicorrente(disponibilita) {
      document.getElementById('deleteDispButton').disabled = true;
      document.getElementById('loadingSpinnerDelete').style.display = 'block';
      axios.delete(`${config.baseUrl}/disponibilita/${encodeURIComponent(disponibilita.id)}/recurrence`)
          .then(() => {
            document.getElementById('deleteDispButton').disabled = false;
            document.getElementById('loadingSpinnerDelete').style.display = 'none';
            document.getElementById('close-modal-delete').click();
            this.caricaDisponibilita(this.currentUser.id);
          })
          .catch(error => {
            document.getElementById('deleteDispButton').disabled = false;
            document.getElementById('loadingSpinnerDelete').style.display = 'none';
            console.error('Errore durante l\'eliminazione della disponibilità:', error);
          });
    },
    eliminaDisponibilita(disponibilita) {
      document.getElementById('deleteDispButton').disabled = true;
      document.getElementById('loadingSpinnerDelete').style.display = 'block';
      axios.delete(`${config.baseUrl}/disponibilita/${encodeURIComponent(disponibilita.id)}`)
          .then(() => {
            document.getElementById('deleteDispButton').disabled = false;
            document.getElementById('loadingSpinnerDelete').style.display = 'none';
            document.getElementById('close-modal-delete').click();
            document.getElementById('close-modal-edit').click();
            this.caricaDisponibilita(this.currentUser.id);
          })
          .catch(error => {
            document.getElementById('deleteDispButton').disabled = false;
            document.getElementById('loadingSpinnerDelete').style.display = 'none';
            console.error('Errore durante l\'eliminazione della disponibilità:', error);
          });
    },
    modificaDisponibilitaRicorrente(editedDisponibilita) {
      document.getElementById('editDispButton').disabled = true;
      document.getElementById('loadingSpinnerEdit').style.display = 'block';
      const disponibilita = {...editedDisponibilita};
      if (disponibilita.parent !== null) {
        disponibilita.parent = disponibilita.parent.id
      }
      axios.put(`${config.baseUrl}/disponibilita/update/recurrence`, disponibilita)
          .then(() => {
            document.getElementById('editDispButton').disabled = false;
            document.getElementById('loadingSpinnerEdit').style.display = 'none';
            document.getElementById('close-modal-edit').click();
            this.caricaDisponibilita(this.currentUser.id);
          })
          .catch(error => {
            document.getElementById('editDispButton').disabled = false;
            document.getElementById('loadingSpinnerEdit').style.display = 'none';
            console.error('Errore durante la modifica della disponibilità ricorrente:', error);
          });
    },
    modificaDisponibilita(editedDisponibilita) {
      document.getElementById('editDispButton').disabled = true;
      document.getElementById('loadingSpinnerEdit').style.display = 'block';
      const disponibilita = {...editedDisponibilita};
      if (disponibilita.parent !== null) {
        disponibilita.parent = disponibilita.parent.id
      }
      axios.put(`${config.baseUrl}/disponibilita/update`, disponibilita)
          .then(() => {
            document.getElementById('editDispButton').disabled = false;
            document.getElementById('loadingSpinnerEdit').style.display = 'none';
            document.getElementById('close-modal-edit').click();
            this.caricaDisponibilita(this.currentUser.id);
          })
          .catch(error => {
            document.getElementById('editDispButton').disabled = false;
            document.getElementById('loadingSpinnerEdit').style.display = 'none';
            console.error('Error in modificaDisponibilita(): ', error)
          });
    },
    saveDisponibilita(disponibilita) {
      disponibilita.utente = this.currentUser;
      document.getElementById('saveDispButton').disabled = true;
      document.getElementById('loadingSpinner').style.display = 'block';

      if (this.selectedDatesCustomFrequency.length === 0) {
        axios.post(`${config.baseUrl}/disponibilita`, disponibilita)
            .then(() => {
              this.resetDisponibilita();
              document.getElementById('saveDispButton').disabled = false;
              document.getElementById('loadingSpinner').style.display = 'none';
              document.getElementById('close-modal-save').click();
              this.caricaDisponibilita(this.currentUser.id);
            })
            .catch(error => {
              document.getElementById('saveDispButton').disabled = false;
              document.getElementById('loadingSpinner').style.display = 'none';
              console.error('Errore durante il salvataggio della disponibilità:', error);
            });
      } else {
        this.selectedDatesCustomFrequency = this.normalizeSelectedDates(this.selectedDatesCustomFrequency);
        const selectedDateDataFine = this.selectedDatesCustomFrequency[this.selectedDatesCustomFrequency.length - 1];
        disponibilita.dataFine = selectedDateDataFine;

        axios.post(`${config.baseUrl}/disponibilita`, disponibilita)
            .then(response => response.data)
            .then(parentDisponibilita => {
              const promises = this.selectedDatesCustomFrequency.map(date => {
                const customDisp = {...disponibilita};
                customDisp.dataInizio = date;
                customDisp.dataFine = selectedDateDataFine;
                customDisp.parent = parentDisponibilita.id;
                customDisp.giornoSettimana = new Date(date).getDay();
                return axios.post(`${config.baseUrl}/disponibilita`, customDisp);
              });
              return Promise.all(promises);
            })
            .then(() => {
              this.resetDisponibilita();
              this.selectedDatesCustomFrequency = [];
              document.getElementById('saveDispButton').disabled = false;
              document.getElementById('loadingSpinner').style.display = 'none';
              document.getElementById('close-modal-save').click();
              this.caricaDisponibilita(this.currentUser.id);
            })
            .then(() => {
              location.reload();
            })
            .catch(error => {
              console.error('Errore durante il salvataggio della disponibilità:', error);
            });
      }
    },
    normalizeSelectedDates(dates) {
      return dates
          .sort((a, b) => a - b)
          .map(date => {
            date.setHours(0, 0, 0, 0);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
          });
    },
    toggleRicorrente() {
      this.disponibilita.ricorrente = !this.disponibilita.ricorrente;
    },
    showModal(disponibilita) {
      this.selectedDisponibilita = disponibilita;
      this.updateHierarchicalData(disponibilita.id);
      let myModal = new Modal(document.getElementById('gestisciModifica'));
      myModal.show();
    },
    updateHierarchicalData(selectedId) {
      const selected = this.disponibilitaList.find(item => item.id === selectedId);
      if (selected) {
        if (selected.parent && selected.parent.id) {
          this.parentDisponibilita = this.disponibilitaList.find(item => item.id === selected.parent.id);
          this.childDisponibilita = this.disponibilitaList
              .filter(item => item.parent && item.parent.id === selected.parent.id && item.id !== selectedId)
              .sort((a, b) => new Date(a.dataInizio) - new Date(b.dataInizio));
        } else {
          this.parentDisponibilita = null;
          this.childDisponibilita = this.disponibilitaList
              .filter(item => item.parent && item.parent.id === selectedId)
              .sort((a, b) => new Date(a.dataInizio) - new Date(b.dataInizio));
        }
      } else {
        this.parentDisponibilita = null;
        this.childDisponibilita = [];
      }
    },
    showModalDelete() {
      let myModal = new Modal(document.getElementById('eliminazioneFuture'));
      myModal.show();
    },
    resetDisponibilita() {
      this.disponibilita = {
        id: '',
        utenteId: this.currentUser.id,
        oraInizio: '',
        oraFine: '',
        dataInizio: '',
        dataFine: '',
        ricorrente: false,
        frequenza: '',
        giornoSettimana: '',
        maxPartecipantiGruppo: '',
        parent: ''
      };
    },
  }
}
</script>