<!-- NavigationTabs.vue -->
<template>
  <ul class="nav d-flex align-items-center">
    <li v-for="tab in tabs" :key="tab.id" class="nav-item">
      <h4
          :id="tab.id"
          class="mb-0 me-5 clickable"
          :class="{ 'active underline': value === tab.id }"
          @click="handleTabClick(tab)"
          @mouseover="handleHover(tab.id, true)"
          @mouseleave="handleHover(tab.id, false)"
      >
        {{ tab.label }}
      </h4>
    </li>
    <li v-if="showActionButton" class="nav-item ms-auto">
      <button
          class="btn btn-primary btn-sm"
          :data-bs-toggle="actionButtonConfig.toggle"
          :data-bs-target="actionButtonConfig.target"
      >
        {{ actionButtonConfig.label }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavigationTabs',
  props: {
    value: { // This replaces activeTab for Vue 2 v-model
      type: String,
      required: true
    },
    tabs: {
      type: Array,
      required: true,
      validator: (tabs) => {
        return tabs.every(tab =>
            typeof tab.id === 'string' &&
            typeof tab.label === 'string' &&
            typeof tab.event === 'string'
        );
      }
    },
    showActionButton: {
      type: Boolean,
      default: false
    },
    actionButtonConfig: {
      type: Object,
      default: () => ({
        label: '+ Add New',
        toggle: 'modal',
        target: '#defaultModal'
      })
    },
    hoverColor: {
      type: String,
      default: '#1f6dff'
    }
  },
  methods: {
    handleTabClick(tab) {
      this.$emit('input', tab.id); // This replaces update:activeTab
      this.$emit('tab-click', tab);
    },
    handleHover(id, isHover) {
      const element = document.getElementById(id);
      if (element) {
        element.style.color = isHover ? this.hoverColor : '';
      }
    }
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.active {
  color: #1f6dff;
}
</style>