<!-- DisponibilitaManager.vue -->
<template>
  <div v-if="disponibilitaList.length" style="margin-bottom: 20%;">
    <div class="row">
      <div class="col-md-4">
        <DateSelector
            :currentPage="currentPage"
            :totalPages="totalPages"
            :currentWeekRange="currentWeekRange"
            :paginatedDates="paginatedDates"
            @prev-page="prevPage"
            @next-page="nextPage"
            @select-date="selectDate"
            :disponibilitaList="disponibilitaList"
        />
      </div>
      <div class="col-md-8 mt-4 pt-3">
        <DisponibilitaList
            :filteredDisponibilita="filteredDisponibilita"
            @show-modal="$emit('show-modal', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DateSelector from './DateSelector.vue';
import DisponibilitaList from './DisponibilitaList.vue';

export default {
  name: 'DisponibilitaManager',
  components: {
    DateSelector,
    DisponibilitaList
  },
  props: {
    disponibilitaList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      selectedDate: null
    }
  },
  computed: {
    totalPages() {
      return Object.keys(this.weeks).length;
    },
    paginatedDates() {
      const weeksArray = Object.keys(this.weeks);
      const currentWeekKey = weeksArray[this.currentPage - 1];
      return this.weeks[currentWeekKey] || [];
    },
    uniqueDates() {
      const dates = this.disponibilitaList.map(disponibilita => disponibilita.dataInizio);
      return [...new Set(dates)].sort((a, b) => new Date(a) - new Date(b));
    },
    weeks() {
      return this.splitByWeeks(this.uniqueDates);
    },
    currentWeekRange() {
      const now = new Date();
      const currentStartOfWeek = this.getStartOfWeek(now);
      const currentEndOfWeek = new Date(currentStartOfWeek);
      currentEndOfWeek.setDate(currentStartOfWeek.getDate() + 6);

      const weeksArray = Object.keys(this.weeks);
      const currentIndex = Math.max(0, Math.min(this.currentPage - 1, weeksArray.length - 1));
      const currentWeekKey = weeksArray[currentIndex];

      if (currentWeekKey) {
        const startOfWeek = new Date(this.getStartOfWeek(new Date(currentWeekKey)));
        startOfWeek.setDate(startOfWeek.getDate() + 7);
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        const normalizeDate = date => {
          date.setHours(0, 0, 0, 0);
          return date.toISOString().split('T')[0];
        };

        if (
            normalizeDate(startOfWeek) === normalizeDate(currentStartOfWeek) &&
            normalizeDate(endOfWeek) === normalizeDate(currentEndOfWeek)
        ) {
          return 'Questa settimana';
        } else {
          const nextStartOfWeek = new Date(currentStartOfWeek);
          nextStartOfWeek.setDate(currentStartOfWeek.getDate() + 7);
          const nextEndOfWeek = new Date(nextStartOfWeek);
          nextEndOfWeek.setDate(nextStartOfWeek.getDate() + 6);

          const lastStartOfWeek = new Date(currentStartOfWeek);
          lastStartOfWeek.setDate(lastStartOfWeek.getDate() - 7);
          const lastEndOfWeek = new Date(lastStartOfWeek);
          lastEndOfWeek.setDate(lastStartOfWeek.getDate() + 6);

          if (
              normalizeDate(startOfWeek) === normalizeDate(nextStartOfWeek) &&
              normalizeDate(endOfWeek) === normalizeDate(nextEndOfWeek)
          ) {
            return 'Prossima settimana';
          } else if (
              normalizeDate(startOfWeek) === normalizeDate(lastStartOfWeek) &&
              normalizeDate(endOfWeek) === normalizeDate(lastEndOfWeek)
          ) {
            return 'Settimana scorsa';
          } else {
            return `${this.formatLocalDate(startOfWeek)} - ${this.formatLocalDate(endOfWeek)}`;
          }
        }
      }
      return '';
    },
    filteredDisponibilita() {
      if (!this.selectedDate) {
        return [];
      }
      return this.disponibilitaList.filter(disponibilita => disponibilita.dataInizio === this.selectedDate);
    },
  },
  methods: {
    getStartOfWeek(date) {
      const currentDate = new Date(date);
      const day = currentDate.getDay();
      const diff = (day === 0 ? -6 : 1) - day;
      const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() + diff));
      startOfWeek.setHours(0, 0, 0, 0);
      return startOfWeek;
    },
    splitByWeeks(dates) {
      const weeks = {};
      dates.forEach(date => {
        const startOfWeek = this.getStartOfWeek(date).toISOString().split('T')[0];
        if (!weeks[startOfWeek]) {
          weeks[startOfWeek] = [];
        }
        weeks[startOfWeek].push(date);
      });
      return weeks;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.selectDate();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.selectDate();
      }
    },
    selectDate(date) {
      this.selectedDate = date;
    },
    formatLocalDate(localDate) {
      const date = new Date(localDate);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  }
}
</script>
