<template>
  <div class="container bg-white mt-3 p-4 rounded">
    <div v-if="currentUser.role === 'tutor'">
      <TutorDashboard :currentUser="currentUser"/>
    </div>

    <div v-else-if="currentUser.role === 'student'">
      <StudentDashboard :current-user="currentUser"/>
    </div>

    <div v-else-if="currentUser.role === 'buddy'">
      <BuddyDashboard :currentUser="currentUser"/>
    </div>
  </div>
</template>

<script>
import BuddyDashboard from "@/components/BuddyDashboard.vue";
import TutorDashboard from "@/components/TutorDashboard.vue";
import StudentDashboard from "@/components/StudentDashboard.vue";

export default {
  name: 'UserDashboard',
  components: {
    StudentDashboard,
    TutorDashboard,
    BuddyDashboard
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  }
}
</script>
