<template>
  <div class="last-minute-lessons">
    <h3>Ripetizioni Last Minute</h3>

    <!-- Subject selection -->
    <div class="subject-selection mb-4">
      <h5>Seleziona la materia</h5>
      <SubjectList
          :items="subjects"
          @select="handleSubjectSelect"
      />
    </div>

    <!-- Available tutors list -->
    <div v-if="selectedSubject" class="available-tutors mt-4">
      <h5>Tutor disponibili per {{ selectedSubject.nome }}</h5>
      <div v-if="loading" class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else-if="availableTutors.length === 0" class="alert alert-info">
        Nessun tutor disponibile al momento per questa materia.
      </div>
      <div v-else class="tutor-list">
        <div v-for="tutor in availableTutors"
             :key="tutor.id"
             class="tutor-card card mb-2 cursor-pointer"
             >
          <div class="card-body d-flex justify-content-between align-items-center">
            <div>
              <h6 class="mb-0" @click="showTutorProfile(tutor)">{{ tutor.nome }} {{ tutor.cognome }}</h6>
            </div>
            <button class="btn btn-primary btn-sm" @click="instantBooking(tutor, currentUserId, selectedSubject)">
              Inizia!
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Profile Modal -->
    <div class="modal fade"
         id="tutorProfileModal"
         tabindex="-1"
         ref="profileModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Profilo Tutor</h5>
            <button type="button"
                    class="btn-close"
                    @click="closeModal">
            </button>
          </div>
          <div class="modal-body">
            <UserProfile
                v-if="selectedTutor"
                :userId="selectedTutor.id"
                :currentUserId="currentUserId"
            />
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    @click="closeModal">
              Chiudi
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import axios from 'axios';
import config from '@/utils/config';
import SubjectList from './SubjectList.vue';
import UserProfile from './UserProfile.vue';
import { Modal } from 'bootstrap';

export default {
  name: 'LastMinuteLessons',
  components: {
    SubjectList,
    UserProfile
  },
  props: {
    currentUserId: {
      type: Number,
      required: true
    }
  },
  setup() {
    const subjects = ref([]);
    const selectedSubject = ref(null);
    const availableTutors = ref([]);
    const loading = ref(false);
    const selectedTutor = ref(null);
    const profileModal = ref(null);
    let modal = null;
    let pollingInterval = null;

    const loadSubjects = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/materia`);
        subjects.value = response.data;
      } catch (error) {
        console.error('Error loading subjects:', error);
      }
    };

    const loadAvailableTutors = async () => {
      if (!selectedSubject.value) return;

      loading.value = true;
      try {
        const response = await axios.get(
            `${config.baseUrl}/utente/available/${selectedSubject.value.nome}`
        );
        availableTutors.value = response.data;
      } catch (error) {
        console.error('Error loading available tutors:', error);
      } finally {
        loading.value = false;
      }
    };

    const handleSubjectSelect = (subject) => {
      selectedSubject.value = subject;
      loadAvailableTutors();
    };

    const showTutorProfile = (tutor) => {
      selectedTutor.value = tutor;
      modal.show();
    };

    const closeModal = () => {
      modal.hide();
      selectedTutor.value = null;
    };

    onMounted(() => {
      loadSubjects();

      // Initialize Bootstrap modal
      modal = new Modal(document.getElementById('tutorProfileModal'));

      // Start polling for available tutors
      pollingInterval = setInterval(loadAvailableTutors, 30000); // Poll every 30 seconds
    });

    onBeforeUnmount(() => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    });
    const instantBooking = async (tutor,currentUserId, selectedSubject) => {
      const ripetizione = {
        disponibilitaId: null,
        allievoId: currentUserId,
        dataOra: new Date().toISOString(),
        materiaNome: selectedSubject.nome,
        insegnanteId: tutor.id,
        gruppoId: null // This is null for individual bookings
      };

      try {
        await axios.post(config.baseUrl + '/ripetizione', ripetizione); // TODO listener qui che on success teletrasporta...
      } catch (error) {
        console.error('Errore:', error.response ? error.response.data : error.message);
      }
    }

    return {
      subjects,
      selectedSubject,
      availableTutors,
      loading,
      selectedTutor,
      profileModal,
      handleSubjectSelect,
      showTutorProfile,
      closeModal,
      instantBooking
    };
  }
};
</script>

<style scoped>
.last-minute-lessons {
  padding: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.tutor-card:hover {
  background-color: #f8f9fa;
}
</style>