<!-- BuddyDashboard.vue -->
<template>
  <div>
    <div v-if="buddyPrimarySection">
      <h3 class="text-center">Scegliere la sezione</h3><br>
      <div id="buddy-preview">
        <div class="custom-card-container">
          <CustomCard
              title="Tutor"
              icon="fas fa-user-tie"
              description="Gestisci le tue disponibilità"
              @click="showBuddyTutorSection = true; buddyPrimarySection = false"
          />
          <CustomCard
              title="Studente"
              icon="fas fa-user-graduate"
              description="Gestisci le tue prenotazioni"
              @click="showBuddyStudentSection = true; buddyPrimarySection = false"
          />
        </div>
      </div>
    </div>
    <TutorDashboard
        v-if="showBuddyTutorSection"
        :currentUser="currentUser"
        @change-section="showBuddyTutorSection = false; buddyPrimarySection = true"
    />
    <div v-if="showBuddyStudentSection">
      <StudentDashboard :current-user="currentUser"
                        @change-section="showBuddyStudentSection = false; buddyPrimarySection = true"
      />
    </div>
  </div>
</template>

<script>
import CustomCard from "@/components/CustomCard.vue";
import TutorDashboard from "@/components/TutorDashboard.vue";
import StudentDashboard from "@/components/StudentDashboard.vue";

export default {
  name: 'BuddyDashboard',
  components: {
    StudentDashboard,
    CustomCard,
    TutorDashboard
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      buddyPrimarySection: true,
      showBuddyTutorSection: false,
      showBuddyStudentSection: false,
    }
  }
}
</script>

<style scoped>
#buddy-preview {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  margin: 0;
}

.custom-card-container {
  display: flex;
  justify-content: space-around;
  width: 80%;
}
</style>
