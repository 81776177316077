<template>
  <div class="modal fade" id="creaNuovaDisp" tabindex="-1" aria-labelledby="gestisciLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="gestisciLabel">Crea disponibilità</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal-save"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="saveDisponibilita" class="mt-3">
            <div class="mb-3">
              <label for="dataInizio"><b>Data Inizio:</b></label>
              <input type="date" v-model="localDisponibilita.dataInizio" id="dataInizio"
                     class="form-control" :min="todayDate" autocomplete="off" required
                     aria-labelledby="dataInizio">
            </div>
            <div class="mb-3">
              <label for="oraInizio"><b>Ora Inizio:</b></label>
              <input type="time" v-model="localDisponibilita.oraInizio" id="oraInizio" class="form-control"
                     autocomplete="off" required>
            </div>
            <div class="mb-3">
              <label for="oraFine"><b>Ora Fine:</b></label>
              <input type="time" v-model="localDisponibilita.oraFine" id="oraFine" class="form-control"
                     autocomplete="off" required>
            </div>
            <div class="mb-3">
              <label for="maxNumPartecipanti"><b>Massimo numero di partecipanti</b> (facoltativo):</label>
              <input type="number" v-model="localDisponibilita.maxPartecipantiGruppo"
                     id="maxNumPartecipanti" class="form-control" autocomplete="off">
            </div>
            <div class="form-check mb-1">
              <label for="ricorrente" class="form-check-label"><b>Ricorrente🔄</b></label>
              <input type="checkbox" class="form-check-input" id="ricorrente"
                     v-model="localDisponibilita.ricorrente" @click="toggleRicorrente"
                     :disabled="!localDisponibilita.dataInizio">
            </div>
            <div v-if="localDisponibilita.ricorrente === true">
              <label for="frequenza"><b>Frequenza:</b></label>
              <select id="frequenza" v-model="localDisponibilita.frequenza" class="form-select"
                      aria-label="Frequenza" required>
                <option value="onceAWeek">Settimanale</option>
                <option value="onceEveryTwoWeeks">Ogni 2 settimane</option>
                <option value="onceAMonth">Mensile</option>
                <option value="custom">Personalizzato...</option>
              </select>
            </div>
            <div v-if="localDisponibilita.frequenza && localDisponibilita.frequenza !== 'custom'">
              <label for="dataFine"><b>Data Fine:</b></label>
              <input type="date" v-model="localDisponibilita.dataFine" id="dataFine" class="form-control"
                     :min="calculateMinDate()" autocomplete="off" required
                     :max="maxEndDate"
                     :disabled="!localDisponibilita.frequenza">
            </div>
            <div v-if="localDisponibilita.frequenza && localDisponibilita.frequenza === 'custom'" class="mt-3">
              <CustomDatePicker
                  v-model="localSelectedDatesCustomFrequency"
                  :disabled-date="disabledDate"
                  :start-date="new Date(localDisponibilita.dataInizio)"
              />
            </div>
            <div class="text-end mt-3">
              <div id="loadingSpinner" style="display: none" class="spinner-border text-muted"></div>
              <button type="submit" class="btn btn-success btn-sm custom-btn" id="saveDispButton">
                Salva
              </button>
              <button type="button" class="btn btn-danger btn-sm custom-btn" data-bs-dismiss="modal">
                Annulla
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomDatePicker from './CustomDatePicker.vue';

export default {
  name: 'ModalCreaNuova',
  components: {
    CustomDatePicker
  },
  props: {
    disponibilita: Object,
    todayDate: String,
    selectedDatesCustomFrequency: Array,
    utenteId: Number
  },
  data() {
    return {
      localDisponibilita: {},
      localSelectedDatesCustomFrequency: []
    }
  },
  watch: {
    disponibilita: {
      immediate: true,
      handler(newVal) {
        this.localDisponibilita = JSON.parse(JSON.stringify(newVal));
      }
    },
    'localDisponibilita.dataInizio': function (newDate) {
      if (newDate) {
        this.localDisponibilita.giornoSettimana = new Date(newDate).getDay();
      } else {
        this.localDisponibilita.giornoSettimana = '';
      }
    },
    selectedDatesCustomFrequency: {
      immediate: true,
      handler(newVal) {
        this.localSelectedDatesCustomFrequency = [...newVal];
      }
    }
  },
  computed: {
    maxEndDate() {
      if (this.localDisponibilita.dataInizio) {
        const maxDate = new Date(this.localDisponibilita.dataInizio);
        maxDate.setFullYear(maxDate.getFullYear() + 1);
        return maxDate.toISOString().split('T')[0];
      }
      return null;
    }
  },
  methods: {
    calculateMinDate() {
      if (!this.localDisponibilita.frequenza) {
        return null;
      }

      const frequenza = this.localDisponibilita.frequenza.toLowerCase();

      if (frequenza === 'onceaweek' && this.localDisponibilita.dataInizio) {
        const minDate = new Date(this.localDisponibilita.dataInizio);
        minDate.setDate(minDate.getDate() + 7);
        return minDate.toISOString().split('T')[0];
      } else if (frequenza === 'onceeverytwoweeks' && this.localDisponibilita.dataInizio) {
        const minDate = new Date(this.localDisponibilita.dataInizio);
        minDate.setDate(minDate.getDate() + 14);
        return minDate.toISOString().split('T')[0];
      } else if (frequenza === 'onceamonth' && this.localDisponibilita.dataInizio) {
        const minDate = new Date(this.localDisponibilita.dataInizio);
        minDate.setMonth(minDate.getMonth() + 1);
        return minDate.toISOString().split('T')[0];
      }

      return null;
    },
    disabledDate(date) {
      const dataInizio = new Date(this.localDisponibilita.dataInizio);
      dataInizio.setDate(dataInizio.getDate());
      return date < dataInizio;
    },
    toggleRicorrente() {
      this.$emit('toggle-ricorrente');
    },
    saveDisponibilita() {
      const disponibilitaToSave = {
        ...this.localDisponibilita,
        selectedDatesCustomFrequency: this.localSelectedDatesCustomFrequency,
        utenteId: this.utenteId
      };

      if (disponibilitaToSave.ricorrente) {
        if (disponibilitaToSave.frequenza === 'custom') {
          // For custom frequency, we'll set the giornoSettimana in the parent component
          disponibilitaToSave.giornoSettimana = null;

          // Filter out dates that are more than 1 year in the future
          const oneYearFromNow = new Date();
          oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
          disponibilitaToSave.selectedDatesCustomFrequency = disponibilitaToSave.selectedDatesCustomFrequency.filter(date => new Date(date) <= oneYearFromNow);
        } else {
          // For other recurrent types, set giornoSettimana based on dataInizio
          disponibilitaToSave.giornoSettimana = new Date(disponibilitaToSave.dataInizio).getDay();

          // Ensure dataFine is not more than 1 year in the future
          const maxEndDate = new Date(disponibilitaToSave.dataInizio);
          maxEndDate.setFullYear(maxEndDate.getFullYear() + 1);
          if (new Date(disponibilitaToSave.dataFine) > maxEndDate) {
            disponibilitaToSave.dataFine = maxEndDate.toISOString().split('T')[0];
          }
        }
      } else {
        // For non-recurrent events, set giornoSettimana based on dataInizio
        disponibilitaToSave.giornoSettimana = new Date(disponibilitaToSave.dataInizio).getDay();
      }

      this.$emit('save-disponibilita', disponibilitaToSave);
    }
  }
}
</script>