<script>
import axios from "axios";
import config from "@/utils/config";

export default {
  props: {
    ripetizione: {
      type: Object,
      required: true,
    },
    userType: {
      type: String,
      required: true,
      validator: (value) => ['studente', 'tutor'].includes(value)
    }
  },
  data() {
    return {
      valutazione: {
        commento: '',
        valutazioneGentilezza: 0,
        valutazionePuntualita: 0,
        valutazioneCompetenzaORendimento: 0,
        votoImpegno: 0,
        valutatoreId: null,
        valutatoId: null,
        ripetizioneId: this.ripetizione ? this.ripetizione.id : null,
        gruppoId: null,
      },
      loading: false,
      classRatings: {
        valutazioneCompetenzaORendimento: 0,
        valutazioneGentilezza: 0,
        valutazionePuntualita: 0,
        votoImpegno: 0,
        valutatoreId: this.ripetizione.insegnanteId,
        valutatoId: null,
        ripetizioneId: this.ripetizione.id,
        gruppoId: null,
      },
      studentRatings: {
        valutazioneCompetenzaORendimento: '',
        valutazioneGentilezza: '',
        valutazionePuntualita: '',
        votoImpegno: '',
        valutatoreId: this.ripetizione.insegnanteId,
        valutatoId: null,
        ripetizioneId: this.ripetizione,
        gruppoId: null,
      },
      showStudentEvaluationSection: false, // Track if additional section is shown
      selectAll: false, // Track select all checkbox
      students: [], // Array to store student data
    };
  },
  mounted() {
    if(this.userType !== 'studente')
      this.ask_for_studenti();
  },
  computed: {
    isSaveDisabled() {
      if (this.userType === 'studente') {
        return this.valutazione.valutazioneGentilezza === null ||
            this.valutazione.valutazionePuntualita === null ||
            this.valutazione.valutazioneCompetenzaORendimento === null;
      } else {
        return this.classRatings.valutazioneCompetenzaORendimento === null ||
            this.classRatings.valutazioneGentilezza === null ||
            this.classRatings.valutazionePuntualita === null ||
            this.classRatings.votoImpegno === null;
      }
    },
    isAnyStudentSelected() {
      return this.students.some(student => student.selected);
    },
  },
  watch: {
    selectAll: {
      handler(newValue) {
        this.students.forEach(student => {
          student.selected = newValue;
        });
      }
    },
  },
  created() {
    if (this.userType === 'studente') {
      this.valutazione.valutatoId = this.ripetizione.insegnante.id;
      this.valutazione.valutatoreId = this.ripetizione.allievo.id;
    }
  },
  methods: {
    updateRating(field, value) {
      this.valutazione[field] = value;
    },
    formatDate(dataOra) {
      const date = new Date(dataOra);
      const giorniSettimana = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];

      const giornoSettimana = giorniSettimana[date.getDay()];
      const giorno = date.getDate().toString().padStart(2, '0');
      const mese = (date.getMonth() + 1).toString().padStart(2, '0');
      const anno = date.getFullYear();

      const ore = date.getHours().toString().padStart(2, '0');
      const minuti = date.getMinutes().toString().padStart(2, '0');

      return `${giornoSettimana} ${giorno}/${mese}/${anno} alle ${ore}:${minuti}`;
    },
    async saveValutazione() {
      console.log(this.valutazione);
      this.loading = true;
      try {
        await axios.post(config.baseUrl + '/valutazione/save', this.valutazione);
        this.$emit('chiudi');
      } catch (error) {
        console.error("Errore nel salvataggio della valutazione", error);
      } finally {
        this.loading = false;
      }
    },
    setClassRating(category, rating) {
      this.classRatings[category] = rating;
    },
    setStudentRating(category, rating) {
      this.studentRatings[category] = rating;
    },
    async addStudentEvaluation() {
      this.showStudentEvaluationSection = true; // Show the additional section
      this.students.forEach(student => {
        student.valutazioneCompetenzaORendimento = this.classRatings.valutazioneCompetenzaORendimento;
        student.valutazioneGentilezza = this.classRatings.valutazioneGentilezza;
        student.valutazionePuntualita = this.classRatings.valutazionePuntualita;
        student.votoImpegno = this.classRatings.votoImpegno;
        student.ripetizioneId = this.ripetizione.id;
        student.valutatoId = student.id;
        student.gruppoId = this.ripetizione.gruppoId;
        student.valutatoreId = this.classRatings.valutatoreId;
      });
    },
    //finished evaluation
    async finishEvaluation() {
      if (this.classRatings.valutazioneCompetenzaORendimento === '' || this.classRatings.valutazioneGentilezza === '' || this.classRatings.valutazionePuntualita === '' || this.classRatings.votoImpegno === '')
        alert('Please valuate the students before finishing the evaluation');

      if (!this.showStudentEvaluationSection) {
        this.students.forEach(student => {
          student.valutazioneCompetenzaORendimento = this.classRatings.valutazioneCompetenzaORendimento;
          student.valutazioneGentilezza = this.classRatings.valutazioneGentilezza;
          student.valutazionePuntualita = this.classRatings.valutazionePuntualita;
          student.votoImpegno = this.classRatings.votoImpegno;
          student.ripetizioneId = this.ripetizione.id;
          student.valutatoId = student.id;
          student.gruppoId = this.ripetizione.gruppoId;
          student.valutatoreId = this.classRatings.valutatoreId;
        });
      }
      await this.finishEvaluationTable();

      // Reset classRatings fields
      this.classRatings.valutazioneCompetenzaORendimento = '';
      this.classRatings.valutazioneGentilezza = '';
      this.classRatings.valutazionePuntualita = '';
      this.classRatings.votoImpegno = '';
    },
    toggleSelectAll(event) {
      this.selectAll = event.target.checked;
    },
    async finishEvaluationTable() {
      if (!this.isAnyStudentSelected) {
        try {
          await axios.post(config.baseUrl + '/valutazione/tutor_ripetizione', this.students);
          this.$emit('chiudi');
        } catch (error) {
          console.error("Errore nel salvataggio della valutazione", error);
        } finally {
          this.loading = false;
        }

        const page = document.querySelector('.valutation-container');
        page.style.display = 'none';
      } else if (this.studentRatings.valutazioneCompetenzaORendimento === '' || this.studentRatings.valutazioneGentilezza === '' || this.studentRatings.valutazionePuntualita === '' || this.studentRatings.votoImpegno === '') {
        alert('Please valuate the selected before finishing the evaluation');
      }
    },
    save() {
      this.students.forEach(student => {
        student.highlight = false;
      });

      console.log('Saving evaluations...');

      if(this.studentRatings.valutazioneCompetenzaORendimento === '' || this.studentRatings.valutazioneGentilezza === '' || this.studentRatings.valutazionePuntualita === '' || this.studentRatings.votoImpegno === '') {
        alert('Please valuate the selected before saving the evaluation');
        return;
      }else{
        this.students.forEach((student) => {
          if (student.selected) {
            student.valutazioneCompetenzaORendimento = this.studentRatings.valutazioneCompetenzaORendimento;
            student.valutazioneGentilezza = this.studentRatings.valutazioneGentilezza;
            student.valutazionePuntualita = this.studentRatings.valutazionePuntualita;
            student.votoImpegno = this.studentRatings.votoImpegno;
            student.valutatoId = student.id;
            student.gruppoId = this.ripetizione.gruppoId;
            student.valutatoreId = this.ripetizione.insegnanteId;
            student.ripetizioneId = this.ripetizione.id;
            student.selected = false;
            student.highlight = true;
            student.different = true;
          }
        });
      }

      if(this.selectAll){
        this.selectAll = false;
      }

      this.studentRatings.valutazioneCompetenzaORendimento = '';
      this.studentRatings.valutazioneGentilezza = '';
      this.studentRatings.valutazionePuntualita = '';
      this.studentRatings.votoImpegno = '';
    },
    async ask_for_studenti() {
      this.classRatings.valutatoreId = this.ripetizione.insegnante.id;
      this.classRatings.gruppoId = this.ripetizione.gruppoId;
      const url = config.baseUrl + '/gruppo/studenti/gruppo_id/' + this.classRatings.gruppoId;
      await axios.get(url).then((response) => {
        this.students = response.data;
        this.students.selected = false;
      }).catch((error) => {
        console.error("Errore nel recupero degli studenti", error);
      });
    }
  }
};
</script>


<template>
  <div class="student-evaluate-tutor" v-if="userType === 'studente'">
    <h4>Valuta il tutor</h4>
    <p>Nome: <strong> {{ripetizione.insegnante.cognome}} {{ripetizione.insegnante.nome}} </strong></p>
    <p>Materia: <strong>{{ ripetizione.materia.nome }} </strong></p>
    <p id="marginfor">Data: <strong> {{ formatDate(ripetizione.dataOra) }} </strong></p>
      <div class="tutor-valuation">
          <label for="valutazioneGentilezza" style="font-weight: 600">Gentilezza</label>
            <div class="rating-labels">
              <span class="label-left">Scarso</span>
              <span class="label-right">Eccellente</span>
            </div>
            <div class="rating">
              <span
                  v-for="value in 5"
                  :key="`rating-valutazioneGentilezza-${value}`"
                  class="star"
                  :class="{ selected: valutazione.valutazioneGentilezza >= value }"
                  @click="updateRating('valutazioneGentilezza', value)"
              >★</span>
            </div>
          <label for="valutazionePuntualita" style="font-weight: 600">Puntualità</label>
            <div class="rating-labels">
              <span class="label-left">Scarso</span>
              <span class="label-right">Eccellente</span>
            </div>
            <div class="rating">
              <span
                  v-for="value in 5"
                  :key="`rating-valutazionePuntualita-${value}`"
                  class="star"
                  :class="{ selected: valutazione.valutazionePuntualita >= value }"
                  @click="updateRating('valutazionePuntualita', value)"
              >★</span>
            </div>
          <label for="valutazioneCompetenzaORendimento" style="font-weight: 600">Competenza o rendimento</label>
            <div class="rating-labels">
              <span class="label-left">Scarso</span>
              <span class="label-right">Eccellente</span>
            </div>
            <div class="rating">
              <span
                  v-for="value in 5"
                  :key="`rating-valutazioneCompetenzaORendimento-${value}`"
                  class="star"
                  :class="{ selected: valutazione.valutazioneCompetenzaORendimento >= value }"
                  @click="updateRating('valutazioneCompetenzaORendimento', value)"
              >★</span>
            </div>
          <label for="votoImpegno" style="font-weight: 600">Impegno</label>
            <div class="rating-labels">
              <span class="label-left">Scarso</span>
              <span class="label-right">Eccellente</span>
            </div>
            <div class="rating">
              <span
                  v-for="value in 5"
                  :key="`rating-votoImpegno-${value}`"
                  class="star"
                  :class="{ selected: valutazione.votoImpegno >= value }"
                  @click="updateRating('votoImpegno', value)"
              >★</span>
            </div>

      <h6>Commento (facoltativo)</h6>
      <textarea v-model="valutazione.commento" class="commento-textarea" placeholder="Scrivi qui il tuo commento..."></textarea>
      </div>

    <div>
      <div class="modal-buttons">
        <div v-if="loading" class="spinner-border">
        </div>
        <div class="modal-btn cancel-btn" @click="$emit('chiudi')">Annulla</div>
        <div class="modal-btn save-btn" @click="isSaveDisabled ? null : saveValutazione()" :class="{ 'disabled': isSaveDisabled }">Salva</div>
      </div>
    </div>
  </div>

  <div v-else >
    <div class="valutation-container">
      <div class="valutation-box" @submit.prevent="finishEvaluation">
        <div v-if="!showStudentEvaluationSection">
          <h2>Valuta la classe</h2>
          <table>
            <tbody>
            <tr>
              <td>Rendimento degli studenti</td>
              <td>
                  <span
                      v-for="star in 5"
                      :key="'performance-star-' + star"
                      class="star"
                      :class="{ selected: star <= classRatings.valutazioneCompetenzaORendimento }"
                      @click="setClassRating('valutazioneCompetenzaORendimento', star)"
                  >★</span>
              </td>
            </tr>
            <tr>
              <td>Gentilezza</td>
              <td>
                  <span
                      v-for="star in 5"
                      :key="'kindness-star-' + star"
                      class="star"
                      :class="{ selected: star <= classRatings.valutazioneGentilezza }"
                      @click="setClassRating('valutazioneGentilezza', star)"
                  >★</span>
              </td>
            </tr>
            <tr>
              <td>Puntualità</td>
              <td>
                  <span
                      v-for="star in 5"
                      :key="'punctuality-star-' + star"
                      class="star"
                      :class="{ selected: star <= classRatings.valutazionePuntualita }"
                      @click="setClassRating('valutazionePuntualita', star)"
                  >★</span>
              </td>
            </tr>
            <tr>
              <td>Impegno</td>
              <td>
                  <span
                      v-for="star in 5"
                      :key="'commitment-star-' + star"
                      class="star"
                      :class="{ selected: star <= classRatings.votoImpegno }"
                      @click="setClassRating('votoImpegno', star)"
                  >★</span>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="button-container">
            <button @click="addStudentEvaluation">Aggiungi valutazione singolo studente</button>
            <button @click="finishEvaluation">Termina</button>
          </div>
        </div>
        <div v-if="showStudentEvaluationSection" class="new-evaluation-section">
          <p style="font-style: italic">*La valutazione singola sostituisce la valutazione della classe*</p>
          <p>Seleziona gli studenti che vuoi valutare e compila il form sottostante. <br> Dopo aver valutato salva i nuovi voti.</p>
          <table class="student-evaluation-table">
            <thead>
            <tr>
              <th><input type="checkbox" v-model="selectAll" @change="toggleSelectAll" /></th>
              <th>Nome Studente</th>
              <th>Rendimento</th>
              <th>Gentilezza</th>
              <th>Puntualità</th>
              <th>Impegno</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(student) in students" :key="student.id" :class="{ highlight: student.highlight, different_from_class: student.different}">
              <td><input type="checkbox" v-model="student.selected" /></td>
              <td>{{ student.nome}} {{student.cognome}}</td>
              <td>{{ student.valutazioneCompetenzaORendimento || classRatings.valutazioneCompetenzaORendimento }}/5</td>
              <td>{{ student.valutazioneGentilezza || classRatings.valutazioneGentilezza }}/5</td>
              <td>{{ student.valutazionePuntualita || classRatings.valutazionePuntualita }}/5</td>
              <td>{{ student.votoImpegno || classRatings.votoImpegno }}/5</td>
            </tr>
            </tbody>
          </table>
          <div v-if="!isAnyStudentSelected" class="d-flex justify-content-end">
            <button class="end-student-button" @click="finishEvaluationTable">Termina</button>
          </div>
            <div v-if="isAnyStudentSelected" class="additional-evaluation-section">
            <table>
              <tbody>
              <tr>
                <td>Rendimento degli studenti</td>
                <td>
                    <span
                        v-for="star in 5"
                        :key="'student-performance-star-' + star"
                        class="star"
                        :class="{ selected: star <= (studentRatings.valutazioneCompetenzaORendimento || classRatings.valutazioneCompetenzaORendimento) }"
                        @click="setStudentRating('valutazioneCompetenzaORendimento', star)"
                    >★</span>
                </td>
              </tr>
              <tr>
                <td>Gentilezza</td>
                <td>
                    <span
                        v-for="star in 5"
                        :key="'student-kindness-star-' + star"
                        class="star"
                        :class="{ selected: star <= (studentRatings.valutazioneGentilezza || classRatings.valutazioneGentilezza) }"
                        @click="setStudentRating('valutazioneGentilezza', star)"
                    >★</span>
                </td>
              </tr>
              <tr>
                <td>Puntualità</td>
                <td>
                    <span
                        v-for="star in 5"
                        :key="'student-punctuality-star-' + star"
                        class="star"
                        :class="{ selected: star <= (studentRatings.valutazionePuntualita || classRatings.valutazionePuntualita) }"
                        @click="setStudentRating('valutazionePuntualita', star)"
                    >★</span>
                </td>
              </tr>
              <tr>
                <td>Impegno</td>
                <td>
                    <span
                        v-for="star in 5"
                        :key="'student-commitment-star-' + star"
                        class="star"
                        :class="{ selected: star <= (studentRatings.votoImpegno || classRatings.votoImpegno) }"
                        @click="setStudentRating('votoImpegno', star)"
                    >★</span>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-end">
              <button @click="save">Save</button>
              <button @click="finishEvaluationTable">Termina</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
#marginfor {
  margin-bottom: 15px;
}

p {
  margin-bottom: 1px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  gap: 10px;
}

.modal-btn {
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  transition: opacity 0.3s ease;
  border: 1px solid transparent;
}

.save-btn {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.cancel-btn {
  background-color: #f1f1f1;
  color: #333;
  border: 1px solid #ccc;
}

.modal-btn:hover {
  opacity: 0.9;
  border-color: #999;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.commento-textarea {
  width: 100%;
  height: 75px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  background-color: white;
  resize: none;
  transition: border-color 0.3s;
  caret-color: gray;
}

.commento-textarea:focus {
  border-color: #007bff;
  outline: none;
}

.new-evaluation-section {
  margin-top: 20px;
  padding: 15px;
  border-top: 1px solid #ddd;
}

.valutation-container {
  display: flex;
  justify-content: center; /* Centers the child elements horizontally */
  align-items: center; /* Centers the child elements vertically */
  width: 100%;
  caret-color: transparent;
}

.valutation-box {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  text-align: left;
  display: flex; /* Enables flexbox on the box */
  flex-direction: column; /* Stack child elements vertically */
  align-items: center; /* Center items horizontally */
}

.valutation-box h2 {
  text-align: center;
  color: #333;
}

table {
  width: auto; /* Allows the table to size based on content */
  max-width: 100%; /* Prevents the table from exceeding the parent width */
  margin-top: 20px;
}

td {
  padding: 10px;
}

.star {
  font-size: 25px;
  color: #ccc;
  cursor: pointer;
}

.tutor-valuation .star.selected,
.star.selected {
  color: #f5c518;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

button:hover {
  background-color: #0056b3;
}

.student-evaluation-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.student-evaluation-table th,
.student-evaluation-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}

.student-evaluation-table input[type="number"] {
  width: 50px;
  text-align: center;
}

.student-evaluation-table input[type="number"] {
  caret-color: gray;
  margin: 0; /* Remove default margin */
  padding: 5px; /* Adjust padding for aesthetics */
  width: 50px; /* Keep your defined width */
  text-align: center; /* Center the text */
  border: 1px solid #ccc; /* Add border for visibility */
  border-radius: 4px; /* Optional: Round the corners */
  box-sizing: border-box; /* Include padding in the element's width */
  outline: none; /* Remove the focus outline */
}

.student-evaluation-table input[type="number"]::-webkit-inner-spin-button,
.student-evaluation-table input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Remove margin */
}

.highlight {
  background-color: #FFEC8D;
  transition: background-color 0.5s ease;
  opacity: 1;
}

.different_from_class {
  color: darkblue;
  font-weight: bold;
}

.end-student-button {
  margin-top: 20px;
}

.student-evaluate-tutor {
  background: white;
  padding: 20px;
  border-radius: 10px;
  caret-color: transparent;
}

.rating-labels {
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Push the labels to opposite ends */
  width: 100%; /* Ensure it spans the full width of the container */
  font-size: 14px; /* Adjust font size for better readability */
  font-weight: 500; /* Slightly bold for emphasis */
  color: #555; /* Neutral gray for text color */
  margin-top: 10px; /* Add spacing above */
}

.label-left {
  text-align: left; /* Align the "Scarso" label to the left */
}

.label-right {
  text-align: right; /* Align the "Eccellente" label to the right */
}

.tutor-valuation .rating {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tutor-valuation .star {
  cursor: pointer;
  font-size: 2rem; /* Adjust the size as needed */
  color: #ccc; /* Default color */
}

</style>