<template>
  <div class="group-profile">
    <div class="basic-info">
      <h2 class="group-name">{{ groupData.nome }}</h2>
      <p class="group-description">{{ groupData.descrizione }}</p>

      <div class="participants-section">
        <h4>👥 Partecipanti</h4>
        <div class="participants-list">
          <div v-for="participant in groupData.partecipanti"
               :key="participant.id"
               class="participant-card"
               @click="navigateToUserProfile(participant.id)">
            <div class="participant-info">
              <h5>{{ participant.nome }} {{ participant.cognome }}</h5>
              <span class="role-badge" v-if="participant.id === groupData.creator.id">
                👑 Creatore
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="evaluations">
      <h4>⭐ Valutazioni del gruppo</h4>
      <div class="ratings" v-if="hasEnoughEvaluations">
        <div class="rating-item" v-if="averageRatings.overall > 0">
          <span class="rating-label">Media complessiva:</span>
          <div class="rating-value">
            <span class="stars">{{ getStarRating(averageRatings.overall) }}</span>
            <span class="numeric-rating">({{ averageRatings.overall.toFixed(1) }}/5)</span>
          </div>
        </div>
        <div class="rating-item" v-if="averageRatings.atmosphere > 0">
          <span class="rating-label">Atmosfera:</span>
          <div class="rating-value">
            <span class="stars">{{ getStarRating(averageRatings.atmosphere) }}</span>
            <span class="numeric-rating">({{ averageRatings.atmosphere.toFixed(1) }}/5)</span>
          </div>
        </div>
        <div class="rating-item" v-if="averageRatings.organization > 0">
          <span class="rating-label">Organizzazione:</span>
          <div class="rating-value">
            <span class="stars">{{ getStarRating(averageRatings.organization) }}</span>
            <span class="numeric-rating">({{ averageRatings.organization.toFixed(1) }}/5)</span>
          </div>
        </div>
        <div class="rating-item" v-if="averageRatings.effectiveness > 0">
          <span class="rating-label">Efficacia:</span>
          <div class="rating-value">
            <span class="stars">{{ getStarRating(averageRatings.effectiveness) }}</span>
            <span class="numeric-rating">({{ averageRatings.effectiveness.toFixed(1) }}/5)</span>
          </div>
        </div>
      </div>
      <div v-else class="no-evaluations">
        📊 Non ci sono abbastanza valutazioni per mostrare una media.
      </div>

      <div class="comments" v-if="anonymizedComments.length > 0">
        <h4>💭 Commenti</h4>
        <div class="comment-box" v-for="(comment, index) in anonymizedComments" :key="index">
          <p>"{{ comment }}"</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import config from "@/utils/config";

export default {
  name: 'GroupProfile',
  props: {
    groupId: {
      type: Number,
      required: true
    }
  },
  emits: ['userSelected'],
  setup(props, { emit }) {
    const groupData = ref({});
    const averageRatings = ref({
      overall: 0,
      atmosphere: 0,
      organization: 0,
      effectiveness: 0
    });
    const anonymizedComments = ref([]);
    const hasEnoughEvaluations = ref(false);

    const getStarRating = (rating) => {
      const fullStars = Math.floor(rating);
      const halfStar = rating % 1 >= 0.5;
      const emptyStars = 5 - Math.ceil(rating);

      return '★'.repeat(fullStars) +
          (halfStar ? '½' : '') +
          '☆'.repeat(emptyStars);
    };

    const fetchGroupData = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/gruppo/${props.groupId}`);
        groupData.value = response.data;
      } catch (error) {
        console.error('Error fetching group data:', error);
      }
    };

    const fetchGroupEvaluations = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/valutazione/groupAggregates/${props.groupId}`);
        const data = response.data;
        if (data.totalEvaluations >= 2) {
          hasEnoughEvaluations.value = true;
          averageRatings.value = data.averages;
          anonymizedComments.value = data.comments.filter(comment => comment.length > 0);
        }
      } catch (error) {
        console.error('Error fetching group evaluations:', error);
      }
    };

    const navigateToUserProfile = (userId) => {
      emit('userSelected', userId);
    };

    onMounted(async () => {
      await fetchGroupData();
      await fetchGroupEvaluations();
    });

    return {
      groupData,
      averageRatings,
      anonymizedComments,
      hasEnoughEvaluations,
      getStarRating,
      navigateToUserProfile
    };
  }
}
</script>

<style scoped>
.group-profile {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.group-name {
  color: #2c3e50;
  margin-bottom: 10px;
}

.group-description {
  color: #666;
  margin-bottom: 30px;
}

.participants-section {
  margin-bottom: 30px;
}

.participants-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.participant-card {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
}

.participant-card:hover {
  transform: translateY(-2px);
  background-color: #e9ecef;
}

.participant-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.participant-info h5 {
  margin: 0;
  color: #2c3e50;
}

.role-badge {
  background-color: #ffd700;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8em;
}

.ratings {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
}

.rating-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.rating-label {
  font-weight: bold;
  min-width: 120px;
}

.rating-value {
  display: flex;
  align-items: center;
  gap: 10px;
}

.stars {
  color: #ffd700;
  font-family: monospace;
}

.numeric-rating {
  color: #666;
  font-size: 0.9em;
}

.comment-box {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin: 10px 0;
  font-style: italic;
}

.no-evaluations {
  text-align: center;
  color: #666;
  padding: 20px;
}
</style>