<template>
  <div>
    <h2 class="mb-4">Materie disponibili per ripetizione</h2>
    <div class="text-end me-4 mb-2">
      <button class="btn btn-danger btn-sm d-inline" @click="$emit('tornaIndietro')">Indietro</button>
    </div>
    <SubjectList :items="materie" @select="selezionaMateria"/>
    <DisponibilitaMateria :materia="materiaSelezionata" v-if="materiaSelezionata" :currentUser="currentUser"/>
  </div>
</template>

<script>
import axios from 'axios';
import DisponibilitaMateria from "@/components/DisponibilitaMateria.vue";
import SubjectList from "@/components/SubjectList.vue";
import config from "@/utils/config";

export default {
  components: { DisponibilitaMateria, SubjectList },
  props: { currentUser: Object },
  data() { return { materie: [], materiaSelezionata: null }; },

  mounted() { this.caricaMaterie(); },

  methods: {
    caricaMaterie(){
      axios.get(config.baseUrl + "/materia").then(response => {
        this.materie = response.data;
      });
    },

    selezionaMateria(materia) {
      this.materiaSelezionata = materia;
    }
  }
};
</script>

<style scoped>
.grid-container, .grid-item, .grid-item:hover { display: none; } /* Hide old styles */
</style>
