import { render, staticRenderFns } from "./ModalCreaNuova.vue?vue&type=template&id=bbfebd34"
import script from "./ModalCreaNuova.vue?vue&type=script&lang=js"
export * from "./ModalCreaNuova.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports