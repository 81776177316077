<template>
  <div>
    <div class="input-group mb-3">
      <input
          v-model="searchQuery"
          @input="filterItems"
          class="form-control"
          placeholder="Cerca..."
      />
      <div class="input-group-append">
        <button type="button" class="btn btn-outline-secondary" @click="clearSearch">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <ul class="list-group">
      <li
          v-for="item in filteredItems"
          :key="item.id"
          @click="clicked(item)"
          class="list-group-item list-group-item-action"
      >
        {{ item.nome }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: Array
  },
  data() {
    return {
      searchQuery: '',
      filteredItems: []
    };
  },
  methods: {
    filterItems() {
      if (this.searchQuery.length > 0) {
        const inputWords = this.searchQuery.toLowerCase().split(' ');
        this.filteredItems = this.items.filter(item => {
          const itemWords = item.nome.toLowerCase().split(' ');
          return inputWords.every(inputWord =>
              itemWords.some(itemWord => itemWord.startsWith(inputWord))
          );
        });
      } else {
        this.filteredItems = [];
      }
    },
    clearSearch() {
      this.searchQuery = '';
      this.filteredItems = [];
    },
    clicked(item) {
      this.$emit('select', item);
      this.clearSearch();
    }
  },
  mounted() {
    this.filteredItems = [];
  }
};
</script>

<style scoped>
.list-group-item-action:hover {
  cursor: pointer;
  background-color: #f8f9fa;
}
</style>
