<template>
  <div :class="['custom-card', `custom-${title.toLowerCase()}`]" @click="$emit('click')">
    <div class="custom-card-header">
      {{ title }}
    </div>
    <div class="custom-card-body">
      <i :class="icon"></i>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomCard',
  props: {
    title: String,
    icon: String,
    description: String
  }
}
</script>

<style scoped>
.custom-card {
  width: 200px;
  border-radius: 8px;
  text-align: center;
  padding: 15px;
  margin: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  color: white;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.custom-card-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.custom-card-body {
  font-size: 16px;
  color: white;
}

.custom-card-body i {
  font-size: 40px;
  margin-bottom: 10px;
}

.custom-tutor {
  background-color: #007bff;
  border: 2px solid #0056b3;
}

.custom-tutor .custom-card-body i {
  color: #eaf2ff;
}

.custom-studente {
  background-color: #28a745;
  border: 2px solid #1e7e34;
}

.custom-studente .custom-card-body i {
  color: #e6ffe6;
}

.custom-tutor:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.custom-studente:hover {
  background-color: #1e7e34;
  transform: translateY(-5px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}
</style>
