<!-- AccordionBody.vue -->
<template>
  <div
      :id="'collapse-' + data"
      :aria-labelledby="'heading-' + data"
      class="collapse"
      data-bs-parent="#accordion"
  >
    <div class="card-body">
      <ul>
        <li v-for="(item, index) in fasce" :key="index">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              Orario:
              <span class="d-inline" style="font-weight: 500">
                {{ formatTime(item.oraInizio) }} - {{ formatTime(item.oraFine) }}
              </span>
            </div>
            <div>
              Tutor:
              <span
                  class="d-inline user-name"
                  style="font-weight: 500"
                  @click="openUserProfile(item.utente?.id)"
              >
                {{ item.utente?.nome }} {{ item.utente?.cognome }}
              </span>
            </div>
            <button
                class="btn btn-sm btn-success"
                @click="$emit('prenota', item)"
            >
              Prenota
            </button>
          </div>
        </li>
      </ul>
    </div>

    <!-- User Profile Modal -->
    <div
        class="modal fade"
        id="userProfileModal"
        tabindex="-1"
        aria-labelledby="userProfileModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="userProfileModalLabel">Profilo Utente</h5>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <UserProfile
                v-if="selectedUserId"
                :userId="selectedUserId"
                :currentUserId="currentUserId"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserProfile from './UserProfile.vue';
import { ref } from 'vue';
import {Modal} from 'bootstrap';

export default {
  name: 'AccordionBody',
  components: {
    UserProfile
  },
  props: {
    data: String,
    fasce: Array,
    currentUserId: {
      type: Number,
      required: true
    }
  },
  setup() {
    const selectedUserId = ref(null);

    return {
      selectedUserId,
    };
  },
  methods: {
    formatTime(timeString) {
      const parts = timeString.split(':');
      return `${parts[0]}:${parts[1]}`;
    },
    openUserProfile(userId) {
      if (!userId) return;
      this.selectedUserId = userId;
      const modal = new Modal(document.getElementById('userProfileModal'));
      modal.show();
    }
  },
  emits: ['prenota']
}
</script>

<style scoped>
.card-body {
  padding: 15px;
}

.card-body ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card-body li {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.card-body li:last-child {
  border-bottom: none;
}

.user-name {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}

.user-name:hover {
  color: #0056b3;
}

.user-preview-popup {
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 15px;
  max-width: 400px;
  max-height: 500px;
  overflow-y: auto;
}

/* Add some transition effects */
.user-preview-popup {
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
