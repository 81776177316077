<!-- DisponibilitaMateria.vue -->
<template>
  <div>
    <AlertMessage :message="alertMessage" :type="alertType" v-if="showAlert" />

    <MateriaTitolo :nome="materia.nome" />

    <div v-if="errore" class="errore">{{ errore }}</div>

    <DisponibilitaAccordion
        :disponibilitaGrouped="disponibilitaGrouped"
        :giornoSettimana="giornoSettimana"
        :isLoading="isLoading"
        @prenota="salvaPrenotazione"
        :currentUserId="currentUser.id"
    />

    <!-- Add this modal inside the template, before closing div -->
    <div class="modal fade" id="bookingTypeModal" tabindex="-1" aria-labelledby="bookingTypeModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="bookingTypeModalLabel">Tipo di prenotazione</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <button class="btn btn-primary w-100 mb-2" @click="handleIndividualBooking">
                Prenota per te stesso
              </button>
              <button class="btn btn-success w-100" @click="showGroupSelection"
                      :disabled="!myGroups.length">
                Prenota per un gruppo
                <span v-if="selectedItem && selectedItem.maxPartecipantiGruppo !== null">
    (max {{ selectedItem.maxPartecipantiGruppo }} partecipanti)
  </span>
              </button>
            </div>

            <!-- Group selection section (initially hidden) -->
            <div v-if="showGroupSelect" class="mt-3">
              <h6>Seleziona il gruppo:</h6>
              <select class="form-select" v-model="selectedGroupId">
                <option value="">Seleziona un gruppo</option>
                <option v-for="group in eligibleGroups"
                        :key="group.id"
                        :value="group.id">
                  {{ group.nome }} ({{ group.partecipanti.length }} partecipanti)
                </option>
              </select>
              <button class="btn btn-primary mt-2"
                      @click="handleGroupBooking"
                      :disabled="!selectedGroupId">
                Conferma prenotazione
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import AlertMessage from './AlertMessage.vue';
import MateriaTitolo from './MateriaTitolo.vue';
import DisponibilitaAccordion from './DisponibilitaAccordion.vue';
import config from "@/utils/config";
import { Modal } from 'bootstrap';

export default {
  name: 'DisponibilitaMateria',
  components: {
    AlertMessage,
    MateriaTitolo,
    DisponibilitaAccordion
  },
  props: {
    materia: {
      type: Object,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      disponibilitaGrouped: {},
      errore: null,
      alertMessage: '',
      alertType: '',
      showAlert: false,
      isLoading: false,
      ripetizione: {
        id: '',
        dataOra: '',
        allievoId: '',
        insegnanteId: '',
        materiaId: '',
        materiaNome: '',
        disponibilitaId: ''
      },
      bookingModal: null,
      selectedItem: null,
      myGroups: [],
      selectedGroupId: null,
      showGroupSelect: false,
      eligibleGroups: [],
    };
  },
  mounted() {
    this.bookingModal = new Modal(document.getElementById('bookingTypeModal'));
    this.fetchMyGroups();
  },
  computed: {
    giornoSettimana() {
      return ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
    }
  },
  watch: {
    materia: {
      immediate: true,
      handler(newMateria) {
        if (newMateria && newMateria.nome) {
          this.caricaDisponibilitaMateria(newMateria.nome);
        }
      }
    }
  },
  methods: {
    async fetchMyGroups() {
      try {
        const response = await axios.get(`${config.baseUrl}/gruppo/myGroups/${this.currentUser.id}`);
        // Fetch complete group data for each group
        const groupsWithDetails = await Promise.all(
            response.data.map(async (group) => {
              const detailResponse = await axios.get(`${config.baseUrl}/gruppo/${group.id}`);
              return detailResponse.data;
            })
        );
        this.myGroups = groupsWithDetails;
      } catch (error) {
        console.error('Error fetching groups:', error);
        this.showAlert = true;
        this.alertType = 'danger';
        this.alertMessage = 'Errore nel caricamento dei gruppi';
      }
    },
    showBookingModal(item) {
      this.selectedItem = item;
      this.showGroupSelect = false;
      this.selectedGroupId = null;
      this.bookingModal.show();
    },

    showGroupSelection() {
      // Filter out groups that are too large for this slot
      if (this.selectedItem.maxPartecipantiGruppo !== null) {
        this.eligibleGroups = this.myGroups.filter(group =>
            group.partecipanti.length <= this.selectedItem.maxPartecipantiGruppo
        );
      } else {
        this.eligibleGroups = this.myGroups;
      }

      if (this.eligibleGroups.length === 0) {
        this.showAlert = true;
        this.alertType = 'warning';
        this.alertMessage = this.selectedItem.maxPartecipantiGruppo
            ? `Non hai gruppi con ${this.selectedItem.maxPartecipantiGruppo} o meno partecipanti.`
            : 'Questa disponibilità non accetta prenotazioni di gruppo.';
        this.bookingModal.hide();
        return;
      }

      this.showGroupSelect = true;
    },

    handleIndividualBooking() {
      this.bookingModal.hide();
      this.processBooking(this.selectedItem, null);
    },

    async handleGroupBooking() {
      if (!this.selectedGroupId) return;

      try {
        // Get the selected group's participants count
        const groupResponse = await axios.get(`${config.baseUrl}/gruppo/${this.selectedGroupId}`);
        const participantsCount = groupResponse.data.partecipanti.length;

        // Check if the group size exceeds the maximum allowed participants
        if (this.selectedItem.maxPartecipantiGruppo !== null &&
            participantsCount > this.selectedItem.maxPartecipantiGruppo) {
          this.showAlert = true;
          this.alertType = 'danger';
          this.alertMessage = `Il gruppo è troppo numeroso. Massimo ${this.selectedItem.maxPartecipantiGruppo} partecipanti permessi.`;
          this.bookingModal.hide();
          return;
        }

        // If validation passes, proceed with booking
        this.bookingModal.hide();
        this.processBooking(this.selectedItem, this.selectedGroupId);
      } catch (error) {
        this.showAlert = true;
        this.alertType = 'danger';
        this.alertMessage = 'Errore durante la verifica del gruppo.';
        console.error('Errore:', error);
      }
    },
    async caricaDisponibilitaMateria(materia) {
      try {
        this.isLoading = true;
        const response = await axios.get(`${config.baseUrl}/disponibilita/insegnanti/${materia}`);
        const disponibilitaFuture = this.filterDisponibilitaFuture(response.data);
        const data = await this.suddividiInFasceOrarie(disponibilitaFuture);
        this.disponibilitaGrouped = this.raggruppaPerData(data);
      } catch (error) {
        this.errore = "Errore nel caricamento dei dati: " + error.message;
      } finally {
        this.isLoading = false;
      }
    },
    filterDisponibilitaFuture(disponibilita) {
      const now = new Date();
      return disponibilita.filter(item => {
        const itemDate = new Date(`${item.dataInizio}T${item.oraInizio}`);
        return itemDate > now;
      });
    },
    async suddividiInFasceOrarie(disponibilitaList) {
      this.fasceOrarie = [];

      disponibilitaList.forEach(disponibilita => {
        let startTime = moment(disponibilita.oraInizio, 'HH:mm');
        let endTime = moment(disponibilita.oraFine, 'HH:mm');

        while (startTime < endTime) {
          let nextTime = moment.min(startTime.clone().add(1, 'hour'), endTime);
          this.fasceOrarie.push({
            disponibilitaId: disponibilita.id,
            data: disponibilita.dataInizio,
            oraInizio: startTime.format('HH:mm'),
            oraFine: nextTime.format('HH:mm'),
            giornoSettimana: disponibilita.giornoSettimana,
            utente: disponibilita.utente,
            maxPartecipantiGruppo: disponibilita.maxPartecipantiGruppo
          });
          startTime = nextTime;
        }
      });

      const promises = this.fasceOrarie.map(fasciaOraria => {
        const localDateTimeString = `${fasciaOraria.data}T${fasciaOraria.oraInizio}`;
        return axios.get(`${config.baseUrl}/ripetizione/prenotazioni/${localDateTimeString}/${fasciaOraria.disponibilitaId}/${fasciaOraria.utente.id}`)
            .then(response => ({ fasciaOraria, remove: response.data }));
      });

      const results = await Promise.all(promises);

      this.fasceOrarie = this.fasceOrarie.filter(fasciaOraria =>
          !results.find(result => result.fasciaOraria === fasciaOraria && result.remove)
      );

      return this.fasceOrarie;
    },
    raggruppaPerData(disponibilitaList) {
      return disponibilitaList.reduce((acc, curr) => {
        if (!acc[curr.data]) {
          acc[curr.data] = [];
        }
        acc[curr.data].push(curr);
        return acc;
      }, {});
    },
    salvaPrenotazione(item) {
      this.showBookingModal(item);
    },
    async processBooking(item, groupId) {
      const localDateTimeString = `${item.data}T${item.oraInizio}`;
      this.ripetizione = {
        ...this.ripetizione,
        disponibilitaId: item.disponibilitaId,
        allievoId: this.currentUser.id,
        dataOra: localDateTimeString,
        materiaNome: this.materia.nome,
        insegnanteId: item.utente.id,
        gruppoId: groupId // This is null for individual bookings
      };

      try {
        await axios.post(config.baseUrl + '/ripetizione', this.ripetizione);
        this.showAlert = true;
        this.alertType = 'success';
        this.alertMessage = 'Prenotazione avvenuta con successo!';
        this.caricaDisponibilitaMateria(this.materia.nome);
      } catch (error) {
        this.showAlert = true;
        this.alertType = 'danger';
        this.alertMessage = 'Non è stato possibile prenotarsi, la sessione di ripetizione è già stata occupata.';
        console.error('Errore:', error.response ? error.response.data : error.message);
      } finally {
        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
      }
    }
  }
}
</script>

<style scoped>
.errore {
  color: red;
  margin-bottom: 15px;
}
</style>
