<template>
  <div class="centered-container">
    <div class="verification-box bg-white p-4 rounded">
      <p>Benvenut*!<br>Ci siamo quasi: per garantire la sicurezza di tutt*,<br>abbiamo bisogno di verificare la tua identità.</p>
      <div id="veriff-root"></div>
    </div>
  </div>
</template>

<style scoped>
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.verification-box {
  max-width: 500px; /* Adjust this value as needed */
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
}
</style>

<script>
import config from "@/utils/config";

export default {
  name: 'VeriffVerification',
  data() {
    return {
      verificationId: null,
    };
  },
  mounted() {
    this.loadScripts().then(() => {
      this.initVeriff();
    });
  },
  methods: {
    loadScripts() {
      return Promise.all([
        this.loadScript('https://cdn.veriff.me/sdk/js/1.5/veriff.min.js'),
        this.loadScript('https://cdn.veriff.me/incontext/js/v1/veriff.js'),
      ]);
    },
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    initVeriff() {
      fetch(config.baseUrl + '/api/veriff/session')
          .then(response => response.json())
          .then(data => {
            // eslint-disable-next-line no-undef
            const veriff = Veriff({
              host: 'https://stationapi.veriff.com',
              apiKey: data.apiKey,
              parentId: 'veriff-root',
              onSession: (err, response) => {
                this.verificationId = response.verification.id;
                window.veriffSDK.createVeriffFrame({url: response.verification.url});
                this.listenForVerificationResult();
              }
            });
            veriff.mount({
              formLabel: {
                vendorData: 'Data di nascita'
              }
            });
          });
    },
    listenForVerificationResult() {
      const eventSource = new EventSource(config.baseUrl + `/api/veriff/status/${this.verificationId}`);
      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.status === 'approved') {
          eventSource.close();
          location.href="/"
        }
      };
      eventSource.onerror = (error) => {
        console.error('EventSource failed:', error);
        eventSource.close();
      };
    },
  },
};
</script>
