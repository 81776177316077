<!-- AccordionHeader.vue -->
<template>
  <div :id="'heading-' + data" class="card-header">
    <h5 class="mb-0">
      <button
          :aria-controls="'collapse-' + data"
          :data-bs-target="'#collapse-' + data"
          data-bs-toggle="collapse"
          aria-expanded="true"
          class="btn btn-link"
          type="button"
      >
        {{ giornoSettimana[fasce[0]?.giornoSettimana] || 'Giorno non valido' }} - {{ formatData(data) }}
      </button>
    </h5>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AccordionHeader',
  props: {
    data: String,
    fasce: Array,
    giornoSettimana: Array
  },
  methods: {
    formatData(data) {
      return moment(data).format('DD-MM-YYYY');
    }
  }
}
</script>

<style scoped>
.btn-link {
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
  display: block;
  width: 100%;
  text-align: left;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: none;
}

.btn-link:focus {
  box-shadow: none;
  outline: none;
}
</style>
