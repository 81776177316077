<script>
import axios from 'axios';
import ListaMaterie from "@/components/ListaMaterie.vue";
import SezioneValutazione from "@/components/SezioneValutazione.vue";
import config from "@/utils/config";
import UserProfile from "@/components/UserProfile.vue";
import GroupProfile from "@/components/GroupProfile.vue";

export default {
  name: 'ListaPrenotazioni',
  components: {
    GroupProfile,
    UserProfile,
    ListaMaterie,
    SezioneValutazione
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    userType: {
      type: String,
      required: true,
      validator: (value) => ['studente', 'tutor'].includes(value)
    }
  },
  data() {
    return {
      selectedRipetizione: null,
      listaRipetizioni: [],
      listaRipetizioniPassate: [],
      listaRipetizioniPassateValutate: [],
      mostraListaMaterie: false,
      loading: false,
      showErrorMessage: false,
      mostraTutte: false,
      mostraTutteValutate: false,
      selectedUserId: null,
      selectedGroupId: null,
    }
  },

  mounted() {
    this.caricaRipetizioni();
  },

  methods: {
    openGroupProfile(groupId) {
      if (!groupId) return;
      this.selectedGroupId = groupId;
    },
    openUserProfile(userId) {
      if (!userId) return;
      this.selectedUserId = userId;
    },
    chiudiModal() {
      this.selectedRipetizione = null;
      this.caricaRipetizioni();
    },

    valutaRipetizione(ripetizione) {
      this.selectedRipetizione = ripetizione;
      if(this.selectedRipetizione.gruppoDTO){
        this.selectedRipetizione.allievoId = undefined
        this.selectedRipetizione.gruppoId = this.selectedRipetizione.gruppoDTO.id
      }
    },

    caricaRipetizioni() {
      const endpoint = this.userType === 'studente'
          ? `/ripetizione/prenotazioni/${encodeURIComponent(this.currentUser.id)}`
          : `/ripetizione/prenotazioni/getRipetizioniByTutor/${encodeURIComponent(this.currentUser.id)}`;

      axios.get(config.baseUrl + endpoint)
          .then(response => {
            this.listaRipetizioni = response.data[1];
            this.listaRipetizioniPassate = response.data[0];
            this.listaRipetizioniPassateValutate = response.data[2] || [];
          })
          .catch(error => {
            console.error("Errore nel caricamento delle ripetizioni", error);
            this.showErrorMessage = true;
            setTimeout(() => {
              this.showErrorMessage = false;
            }, 5000);
          })
          .finally(() => {
            this.loading = false;
          });
    },

    mostraListaMaterieF() {
      this.mostraListaMaterie = true;
    },

    nascondiListaMaterieF() {
      this.mostraListaMaterie = false;
      this.caricaRipetizioni();
    },

    cancellaPrenotazione(id) {
      axios.delete(config.baseUrl + `/ripetizione/${id}`)
          .then(() => {
            this.caricaRipetizioni();
          })
          .catch(error => {
            console.error("Errore nella eliminazione.", error)
          });
    },

    formatDataOra(dataOra) {
      let data = new Date(dataOra);
      let giorno = ("0" + data.getDate()).slice(-2);
      let mese = ("0" + (data.getMonth() + 1)).slice(-2);
      let anno = data.getFullYear();
      let ora = ("0" + data.getHours()).slice(-2);
      let minuti = ("0" + data.getMinutes()).slice(-2);
      let secondi = ("0" + data.getSeconds()).slice(-2);
      return `${giorno}/${mese}/${anno} - ${ora}:${minuti}:${secondi}`;
    },
  }
}
</script>

<template>
  <div class="mb-5" style="caret-color: transparent">
    <div v-if="loading" class="loading-overlay">
      <p>Caricamento in corso...</p>
    </div>

    <div v-if="mostraListaMaterie && userType === 'studente'">
      <ListaMaterie @tornaIndietro="nascondiListaMaterieF" :currentUser="currentUser"/>
    </div>

    <div
        v-else-if="!loading && (listaRipetizioni.length || listaRipetizioniPassate.length || listaRipetizioniPassateValutate.length)">
      <h2 class="mb-4 text-center">{{ userType === 'studente' ? 'Prenotazioni' : 'Ripetizioni' }}</h2>
      <hr>
      <div v-if="showErrorMessage" class="alert alert-danger" role="alert">
        Errore durante l'eliminazione.
      </div>
      <h4 class="mb-3">{{ userType === 'studente' ? 'Ripetizioni in programma' : 'Prenotazioni programmate' }}</h4>
      <div v-if="listaRipetizioni.length">
        <table class="table table-responsive rounded table-striped">
          <thead class="table-info">
          <tr>
            <th>Data e Ora</th>
            <th>Materia</th>
            <th>{{ userType === 'studente' ? 'Tutor' : 'Studente' }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="ripetizione in listaRipetizioni" :key="ripetizione.id" class="prenotazione-riga">
            <td>{{ formatDataOra(ripetizione.dataOra) }}</td>
            <td>{{ ripetizione.materia.nome }}</td>
            <td>
                <span v-if="userType !== 'studente' && ripetizione.gruppoDTO"
                      class="user-name-link"
                      @click="openGroupProfile(ripetizione.gruppoDTO.id)">
                  {{ ripetizione.gruppoDTO.nome }} (Gruppo)
                </span>
                <span v-else
                                  class="user-name-link"
                                  @click="openUserProfile(userType === 'studente' ? ripetizione.insegnante.id : ripetizione.allievo.id)">
                  {{ userType === 'studente' ?
                                `${ripetizione.insegnante.nome} ${ripetizione.insegnante.cognome}` :
                                `${ripetizione.allievo.nome} ${ripetizione.allievo.cognome}`
                              }}
                </span>
            </td>
            <td class="text-center">
              <button v-if="ripetizione.allievo.id === currentUser.id || ripetizione.insegnante.id === currentUser.id" class="btn btn-sm btn-danger" @click="cancellaPrenotazione(ripetizione.id)">Cancella</button>
              <span v-else>{{ripetizione.gruppoDTO.nome}}</span>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-if="userType === 'studente'" class="text-center mt-4">
          <button class="btn btn-success btn-sm" @click="mostraListaMaterieF()">+ Prenota</button>
        </div>
      </div>
      <div v-else-if="!listaRipetizioni.length" class="d-inline">
        {{
          userType === 'studente' ? 'Non hai ripetizioni in programma.' : 'Non ci sono prenotazioni di ripetizioni in programma.'
        }}
        <button v-if="userType === 'studente'" class="btn btn-success btn-sm d-inline" @click="mostraListaMaterieF()">+
          Prenota
        </button>
      </div>

      <h4 class="mb-3 mt-5">
        {{ userType === 'studente' ? 'Ripetizioni passate da valutare' : 'Prenotazioni passate da valutare' }}</h4>
      <div v-if="listaRipetizioniPassate.length">
        <table class="table table-responsive rounded table-striped">
          <thead class="table-info">
          <tr>
            <th>Data e Ora</th>
            <th>Materia</th>
            <th>{{ userType === 'studente' ? 'Tutor' : 'Studente' }}</th>
            <th class="text-center">Valutazione</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(ripetizionePassata) in (mostraTutte ? listaRipetizioniPassate : listaRipetizioniPassate.slice(0, 5))"
              :key="ripetizionePassata.id">
            <td>{{ formatDataOra(ripetizionePassata.dataOra) }}</td>
            <td>{{ ripetizionePassata.materia.nome }}</td>
            <td>
                <span v-if="userType !== 'studente' && ripetizionePassata.gruppoDTO"
                      class="user-name-link"
                      @click="openGroupProfile(ripetizionePassata.gruppoDTO.id)">
                  {{ ripetizionePassata.gruppoDTO.nome }} (Gruppo)
                </span>
              <span v-else
                    class="user-name-link"
                    @click="openUserProfile(userType === 'studente' ? ripetizionePassata.insegnante.id : ripetizionePassata.allievo.id)">
                  {{ userType === 'studente' ?
                  `${ripetizionePassata.insegnante.nome} ${ripetizionePassata.insegnante.cognome}` :
                  `${ripetizionePassata.allievo.nome} ${ripetizionePassata.allievo.cognome}`
                }}
                </span>
            </td>
            <td class="text-center">
              <button v-if="ripetizionePassata.allievo.id === currentUser.id || ripetizionePassata.insegnante.id === currentUser.id" class="btn btn-sm btn-primary" @click="valutaRipetizione(ripetizionePassata)">Valuta</button>
              <span v-else>{{ripetizionePassata.gruppoDTO.nome}}</span>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="text-center" v-if="listaRipetizioniPassate.length > 5">
          <button v-if="!mostraTutte" class="btn btn-secondary btn-sm mt-1" @click="mostraTutte = true">Mostra di più
          </button>
          <button v-else class="btn btn-secondary btn-sm mt-1" @click="mostraTutte = false">Mostra di meno</button>
        </div>
      </div>
      <div v-else>
        {{
          userType === 'studente' ? 'Non hai alcuna ripetizione passata da valutare.' : 'Non ci sono prenotazioni passate da valutare.'
        }}
      </div>

      <h4 class="mb-3 mt-5">
        {{ userType === 'studente' ? 'Ripetizioni passate valutate' : 'Prenotazioni passate valutate' }}</h4>
      <div v-if="listaRipetizioniPassateValutate.length">
        <table class="table table-responsive rounded table-striped">
          <thead class="table-info">
          <tr>
            <th>Data e Ora</th>
            <th>Materia</th>
            <th>{{ userType === 'studente' ? 'Tutor' : 'Studente' }}</th>
            <th class="text-center">Valutazione</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(ripetizionePassata) in (mostraTutteValutate ? listaRipetizioniPassateValutate : listaRipetizioniPassateValutate.slice(0, 5))"
              :key="ripetizionePassata.id">
            <td>{{ formatDataOra(ripetizionePassata.dataOra) }}</td>
            <td>{{ ripetizionePassata.materia.nome }}</td>
            <td>
                <span v-if="userType !== 'studente' && ripetizionePassata.gruppoDTO"
                      class="user-name-link"
                      @click="openGroupProfile(ripetizionePassata.gruppoDTO.id)">
                  {{ ripetizionePassata.gruppoDTO.nome }} (Gruppo)
                </span>
              <span v-else
                    class="user-name-link"
                    @click="openUserProfile(userType === 'studente' ? ripetizionePassata.insegnante.id : ripetizionePassata.allievo.id)">
                  {{ userType === 'studente' ?
                  `${ripetizionePassata.insegnante.nome} ${ripetizionePassata.insegnante.cognome}` :
                  `${ripetizionePassata.allievo.nome} ${ripetizionePassata.allievo.cognome}`
                }}
                </span>
            </td>
            <td class="text-center">Valutata</td>
          </tr>
          </tbody>
        </table>
        <div class="text-center" v-if="listaRipetizioniPassateValutate.length > 5">
          <button v-if="!mostraTutteValutate" class="btn btn-secondary btn-sm mt-1" @click="mostraTutteValutate = true">
            Mostra di più
          </button>
          <button v-else class="btn btn-secondary btn-sm mt-1" @click="mostraTutteValutate = false">Mostra di meno
          </button>
        </div>
      </div>
      <div v-else>
        {{
          userType === 'studente' ? 'Non hai valutato alcuna ripetizione passata.' : 'Non ci sono prenotazioni passate valutate.'
        }}
      </div>
    </div>

    <div
        v-else-if="!loading && !listaRipetizioni.length && !listaRipetizioniPassate.length && !listaRipetizioniPassateValutate.length">
      <h2 class="mb-2">{{ userType === 'studente' ? 'Le tue prenotazioni' : 'Le tue ripetizioni' }}</h2>
      <p class="mt-3 d-inline">{{
          userType === 'studente' ? 'Non hai ancora effettuato alcuna prenotazione.' : 'Non sono presenti prenotazioni.'
        }}</p>
      <button v-if="userType === 'studente'" class="btn btn-success btn-sm d-inline ms-2"
              @click="mostraListaMaterieF()">+ Prenota
      </button>
    </div>

    <div v-if="selectedRipetizione" class="modal-overlay" @click="chiudiModal">
      <div class="modal-content" @click.stop>
        <button class="close-button" @click="chiudiModal">×</button>
        <SezioneValutazione
            :ripetizione="selectedRipetizione"
            @chiudi="chiudiModal"
            :user-type="userType"
        />
      </div>
    </div>
    <div v-if="selectedUserId" class="modal-overlay" @click="selectedUserId = null">
      <div class="modal-content" @click.stop>
        <button class="close-button" @click="selectedUserId = null">×</button>
        <UserProfile
            :userId="selectedUserId"
            :currentUserId="currentUser.id"
        />
      </div>
    </div>
    <!-- Add this new modal for group profile -->
    <div v-if="selectedGroupId" class="modal-overlay" @click="selectedGroupId = null">
      <div class="modal-content" @click.stop>
        <button class="close-button" @click="selectedGroupId = null">×</button>
        <GroupProfile
            :groupId="selectedGroupId"
            @userSelected="openUserProfile"
        />
      </div>
    </div>
    <!-- Add this new modal at the bottom of your template -->
    <div v-if="selectedUserId" class="modal-overlay" @click="selectedUserId = null">
      <div class="modal-content" @click.stop>
        <button class="close-button" @click="selectedUserId = null">×</button>
        <UserProfile
            :userId="selectedUserId"
            :currentUserId="currentUser.id"
        />
      </div>
    </div>
  </div>
  <!-- Add this new modal at the bottom of your template -->
</template>

<style scoped>

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #333;
  z-index: 1000;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
}

/* Modal Content */
.modal-content {
  background-color: white;
  padding: 25px 25px; /* Ridotto il padding laterale */
  border-radius: 10px;
  width: 400px; /* Ridotto la larghezza del contenuto */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  max-width: 90%;
}

/* Pulsante di chiusura */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
  transition: color 0.2s ease-in-out;
}

.close-button:hover {
  color: #ff5f5f;
}

.user-name-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.user-name-link:hover {
  color: #0056b3;
}

/* Adjust the modal content style for the user profile */
.modal-content {
  width: 600px; /* Wider to accommodate the user profile */
  max-height: 80vh;
  overflow-y: auto;
}
</style>