<!-- AlertMessage.vue -->
<template>
  <div :class="['alert', `alert-${type}`]" role="alert">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'AlertMessage',
  props: {
    message: String,
    type: String
  }
}
</script>
