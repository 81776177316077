<script>
import ListaPrenotazioni from "@/components/ListaPrenotazioni.vue";
import ListaInterazioni from "@/components/ListaInterazioni.vue";
import NavigationTabs from "@/components/NavigationTabs.vue";
import GestioneMaterie from "@/components/GestioneMaterie.vue";
import UserProfile from "@/components/UserProfile.vue";
import GestioneGruppi from "@/components/GestioneGruppi.vue";
import LastMinuteLessons from "@/components/LastMinuteLessons.vue";

export default {
  name: 'StudentDashboard',
  components: {
    LastMinuteLessons,
    GestioneGruppi,
    UserProfile,
    GestioneMaterie,
    NavigationTabs,
    ListaPrenotazioni,
    ListaInterazioni,
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTabId: 'sezione-prenotazioni',
      navigationTabs: [
        {
          id: 'sezione-prenotazioni',
          label: 'Prenotazioni',
          event: 'show-prenotazioni'
        },
        {
          id: 'sezione-interazioni',
          label: 'Interazioni',
          event: 'show-interazioni'
        },
        {
          id: 'sezione-materie',
          label: 'Gestione Materie',
          event: 'show-materie'
        },
        {
          id: 'sezione-profilo',
          label: 'Profilo',
          event: 'show-profilo'
        },
        {
          id: 'sezione-gruppi',
          label: 'Gruppi',
          event: 'show-gruppi'
        },
        {
          id: 'sezione-lastminute',
          label: 'Last Minute',
          event: 'show-lastminute'
        }
      ]
    }
  },
  computed: {
    showPrenSection() {
      return this.activeTabId === 'sezione-prenotazioni';
    },
    showInterazioniSection() {
      return this.activeTabId === 'sezione-interazioni';
    },
    showMaterieSection() {
      return this.activeTabId === 'sezione-materie';
    },
    showProfileSection() {
      return this.activeTabId === 'sezione-profilo';
    },
    showGruppiSection() {
      return this.activeTabId === 'sezione-gruppi';
    },
    showLastMinuteSection(){
      return this.activeTabId === 'sezione-lastminute';
    }
  },
  methods: {
    handleTabClick(tab) {
      this.$emit(tab.event);
      switch (tab.event) {
        case 'show-prenotazioni':
          this.emitSectionChange({
            pren: true,
            interazioni: false,
            materie: false,
            profile: false,
            gruppi: false,
            lastminute: false
          });
          break;
        case 'show-interazioni':
          this.emitSectionChange({
            pren: false,
            interazioni: true,
            materie:false,
            profile: false,
            gruppi: false,
            lastminute: false
          });
          break;
        case 'show-materie':
          this.emitSectionChange({
            pren: false,
            interazioni: false,
            materie: true,
            profile: false,
            gruppi: false,
            lastminute: false
          });
          break;
        case 'show-profilo':
          this.emitSectionChange({
            pren: false,
            interazioni: false,
            materie: false,
            profile: true,
            gruppi: false,
            lastminute: false
          });
          break;
        case 'show-gruppi':
          this.emitSectionChange({
            pren: false,
            interazioni: false,
            materie: false,
            profile: false,
            gruppi: true,
            lastminute: false
          });
          break;
        case 'show-lastminute':
          this.emitSectionChange({
            pren: false,
            interazioni: false,
            materie: false,
            profile: false,
            gruppi: false,
            lastminute: true
          });
          break;

      }
    },
    emitSectionChange({pren, interazioni, materie, profile, gruppi, lastminute}) {
      this.$emit('update:showPrenSection', pren);
      this.$emit('update:showInterazioniSection', interazioni);
      this.$emit('update:showMaterieSection', materie);
      this.$emit('update:showProfileSection', profile);
      this.$emit('update:showGruppiSection', gruppi);
      this.$emit('update:showLastMinuteSection', lastminute);
    }
  }
}
</script>

<template>
  <div>
    <button v-if="currentUser.role==='buddy'" class="btn mb-3 btn-warning btn-sm" @click="$emit('change-section')">
      ← Cambia sezione
    </button>
    <NavigationTabs
        v-model="activeTabId"
        :tabs="navigationTabs"
        @tab-click="handleTabClick"
    />

    <hr>

    <ListaPrenotazioni v-if="showPrenSection" :currentUser="currentUser" :user-type="'studente'"/>
    <ListaInterazioni v-if="showInterazioniSection" :current-user="currentUser"/>
    <GestioneMaterie v-if="showMaterieSection" :current-user="currentUser"/>
    <UserProfile
        v-if="showProfileSection"
        :userId="currentUser.id"
        :currentUserId="currentUser.id"
    />
    <GestioneGruppi
        v-if="showGruppiSection"
        :current-user="currentUser"
    />
    <LastMinuteLessons v-if="showLastMinuteSection" :currentUserId="currentUser.id" />
  </div>
</template>
