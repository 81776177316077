<template>
  <div class="user-profile">
    <div class="basic-info" v-if="!isOwnProfile">
      <h2 class="user-name">{{ userData.nome }} {{ userData.cognome }}</h2>

      <div class="info-section" v-if="teachingSubjects.length > 0">
        <h4>📚 Materie insegnate:</h4>
        <ul class="info-list">
          <li v-for="subject in teachingSubjects" :key="subject.id">
            {{ subject.nome }}
          </li>
        </ul>
      </div>

      <div class="info-section" v-if="declaredMarks.length > 0">
        <h4>📝 Voti dichiarati:</h4>
        <ul class="info-list">
          <li v-for="mark in declaredMarks" :key="mark.id">
            {{ mark.materia.nome }}: <strong>{{ mark.voto }}</strong>
          </li>
        </ul>
      </div>

      <div class="info-section" v-if="neededSubjects.length > 0">
        <h4>🎯 Materie da recuperare:</h4>
        <ul class="info-list">
          <li v-for="subject in neededSubjects" :key="subject.id">
            {{ subject.materiaNome }}
          </li>
        </ul>
      </div>
    </div>

    <div class="evaluations">
      <h4>⭐ Valutazioni ricevute</h4>
      <div class="ratings" v-if="hasEnoughEvaluations">
        <div class="rating-item" v-if="averageRatings.overall > 0">
          <span class="rating-label">Media complessiva:</span>
          <div class="rating-value">
            <span class="stars">{{ getStarRating(averageRatings.overall) }}</span>
            <span class="numeric-rating">({{ averageRatings.overall.toFixed(1) }}/5)</span>
          </div>
        </div>
        <div class="rating-item" v-if="averageRatings.kindness > 0">
          <span class="rating-label">Gentilezza:</span>
          <div class="rating-value">
            <span class="stars">{{ getStarRating(averageRatings.kindness) }}</span>
            <span class="numeric-rating">({{ averageRatings.kindness.toFixed(1) }}/5)</span>
          </div>
        </div>
        <div class="rating-item" v-if="averageRatings.punctuality > 0">
          <span class="rating-label">Puntualità:</span>
          <div class="rating-value">
            <span class="stars">{{ getStarRating(averageRatings.punctuality) }}</span>
            <span class="numeric-rating">({{ averageRatings.punctuality.toFixed(1) }}/5)</span>
          </div>
        </div>
        <div class="rating-item" v-if="averageRatings.competenceOrPerformance > 0">
          <span class="rating-label">{{ isOwnProfile ? 'Competenza' : 'Rendimento' }}:</span>
          <div class="rating-value">
            <span class="stars">{{ getStarRating(averageRatings.competenceOrPerformance) }}</span>
            <span class="numeric-rating">({{ averageRatings.competenceOrPerformance.toFixed(1) }}/5)</span>
          </div>
        </div>
        <div class="rating-item" v-if="averageRatings.commitment > 0">
          <span class="rating-label">Impegno:</span>
          <div class="rating-value">
            <span class="stars">{{ getStarRating(averageRatings.commitment) }}</span>
            <span class="numeric-rating">({{ averageRatings.commitment.toFixed(1) }}/5)</span>
          </div>
        </div>
      </div>
      <div v-else class="no-evaluations">
        📊 Non ci sono abbastanza valutazioni per mostrare una media.
      </div>
      <div class="comments" v-if="anonymizedComments.length > 0">
        <h4>💭 Commenti</h4>
        <div class="comment-box" v-for="(comment, index) in anonymizedComments" :key="index">
          <p>"{{ comment }}"</p>
        </div>
      </div>
    </div>
    <div class="badges-section">
      <h4>🏆 Badge ottenuti</h4>
      <div class="badges-container" v-if="badges.length > 0">
        <div v-for="badge in badges" :key="badge.badge.nome" class="badge-item">
          <div class="badge-icon" :class="getBadgeColorClass(badge.badge.tipo)">
            {{ getBadgeIcon(badge.badge.tipo) }}
          </div>
          <div class="badge-info">
            <h4>{{ badge.badge.nome }}</h4>
            <p class="badge-level">Livello: {{ badge.badge.livello }}</p>
            <p class="badge-type">{{ badge.badge.tipo }}</p>
          </div>
        </div>
      </div>
      <div v-else class="no-badges">
        Nessun badge ottenuto finora.
      </div>
    </div>
  </div>
</template>

<style scoped>
.user-profile {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.user-name {
  color: #2c3e50;
  margin-bottom: 20px;
}

.info-section {
  margin-bottom: 20px;
}

.info-list {
  list-style-type: none;
  padding-left: 20px;
}

.info-list li {
  margin: 5px 0;
}

.ratings {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
}

.rating-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.rating-label {
  font-weight: bold;
  min-width: 120px;
}

.rating-value {
  display: flex;
  align-items: center;
  gap: 10px;
}

.stars {
  color: #ffd700;
  font-family: monospace;
}

.numeric-rating {
  color: #666;
  font-size: 0.9em;
}

.comment-box {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin: 10px 0;
  font-style: italic;
}

.no-evaluations {
  text-align: center;
  color: #666;
  padding: 20px;
}

.badges-section {
  margin-top: 30px;
}

.badges-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 15px;
}

.badge-item {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  transition: transform 0.2s;
}

.badge-item:hover {
  transform: translateY(-2px);
}

.badge-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.badge-info {
  flex: 1;
}

.badge-info h4 {
  margin: 0 0 5px 0;
  color: #2c3e50;
}

.badge-level {
  margin: 0;
  font-size: 0.9em;
  color: #666;
}

.badge-type {
  margin: 5px 0 0 0;
  font-size: 0.8em;
  color: #888;
}

.badge-teaching { background-color: #e3f2fd; }
.badge-learning { background-color: #f3e5f5; }
.badge-social { background-color: #e8f5e9; }
.badge-achievement { background-color: #fff3e0; }

.no-badges {
  text-align: center;
  color: #666;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

</style>

<script>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import config from "@/utils/config";

export default {
  name: 'UserProfile',
  props: {
    userId: {
      type: Number,
      required: true
    },
    currentUserId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const userData = ref({});
    const teachingSubjects = ref([]);
    const declaredMarks = ref([]);
    const neededSubjects = ref([]);
    const averageRatings = ref({
      overall: 0,
      kindness: 0,
      punctuality: 0,
      competenceOrPerformance: 0,
      commitment: 0
    });
    const anonymizedComments = ref([]);
    const badges = ref([]);

    const isOwnProfile = computed(() => props.userId === props.currentUserId);
    const hasEnoughEvaluations = ref(false);

    const hasAnyRatings = computed(() => {
      return Object.values(averageRatings.value).some(rating => rating > 0);
    });

    const getStarRating = (rating) => {
      const fullStars = Math.floor(rating);
      const halfStar = rating % 1 >= 0.5;
      const emptyStars = 5 - Math.ceil(rating);

      return '★'.repeat(fullStars) +
          (halfStar ? '½' : '') +
          '☆'.repeat(emptyStars);
    };

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/utente/${props.userId}`);
        userData.value = response.data;
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchEvaluations = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/valutazione/aggregates/${props.userId}`);
        const data = response.data;
        if (data.totalEvaluations >= 2) {
          hasEnoughEvaluations.value = true;
          averageRatings.value = data.averages;
          anonymizedComments.value = data.comments.filter(comment => comment.length > 0);
        }
      } catch (error) {
        console.error('Error fetching evaluations:', error);
      }
    };

    const fetchAdditionalData = async () => {
      if (!isOwnProfile.value) {
        try {
          const [teachingResp, marksResp, neededResp] = await Promise.all([
            axios.get(`${config.baseUrl}/utenteInsegnaMateria/byUser/${props.userId}`),
            axios.get(`${config.baseUrl}/voto-dichiarato/utente/${props.userId}`),
            axios.get(`${config.baseUrl}/utenteRecuperaMateria/utente/${props.userId}`)
          ]);

          teachingSubjects.value = teachingResp.data;
          declaredMarks.value = marksResp.data;
          neededSubjects.value = neededResp.data;
        } catch (error) {
          console.error('Error fetching additional data:', error);
        }
      }
    };

    const fetchBadges = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/acquisizione/utente/${props.userId}`);
        badges.value = response.data;
      } catch (error) {
        console.error('Error fetching badges:', error);
      }
    };

    const getBadgeIcon = (type) => {
      const icons = {
        'Insegnamento': '👨‍🏫',
        'Apprendimento': '📚',
        'Sociale': '🤝',
        'Achievement': '🏆'
      };
      return icons[type] || '🎯';
    };

    const getBadgeColorClass = (type) => {
      return `badge-${type.toLowerCase()}`;
    };

    onMounted(async () => {
      await fetchUserData();
      await fetchEvaluations();
      await fetchAdditionalData();
      await fetchBadges()
    });

    return {
      userData,
      teachingSubjects,
      declaredMarks,
      neededSubjects,
      averageRatings,
      anonymizedComments,
      isOwnProfile,
      hasEnoughEvaluations,
      hasAnyRatings,
      getStarRating,
      badges,
      getBadgeIcon,
      getBadgeColorClass
    };
  }
}
</script>
