<template>
  <div class="container mt-4">
    <h2 class="mb-4">Interazioni recenti</h2>
    <div v-if="interazioni.length === 0" class="alert alert-info" role="alert">
      Nessuna interazione nelle ultime 72 ore.
    </div>
    <div v-else class="row row-cols-1 row-cols-md-2 g-4">
      <div v-for="interazione in interazioni" :key="interazione.id" class="col">
        <div class="card h-100 shadow-sm">
          <div class="card-body">
            <p class="card-text">
              <strong>Inizio:</strong> {{ formatDate(interazione.inizio) }}<br>
              <strong>Fine:</strong> {{ formatDate(interazione.fine) }}
            </p>
            <h6 class="card-subtitle mb-2 text-muted">Partecipanti</h6>
            <ul class="list-unstyled">
              <li v-for="partecipante in interazione.partecipanti" :key="partecipante.id">
                {{ partecipante.nome }} {{ partecipante.cognome }}
                <button
                    v-if="partecipante.id !== currentUser.id"
                    @click="openReportModal(interazione.id, partecipante.id)"
                    class="btn btn-sm btn-warning ml-2"
                    :disabled="isReported(interazione.id, partecipante.id)"
                >
                  {{ isReported(interazione.id, partecipante.id) ? 'Segnalato' : 'Segnala' }}
                </button>
                <button
                    v-if="partecipante.id !== currentUser.id"
                    @click="toggleBlock(partecipante.id)"
                    class="btn btn-sm ml-2"
                    :class="(isBlocked(partecipante.id) ? 'btn-success' : 'btn-danger' ) + ' littlemargin'"
                >
                  {{ isBlocked(partecipante.id) ? 'Sblocca' : 'Blocca' }}
                </button>
                <span v-else>(tu)</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <h2 class="mt-5 mb-4">Utenti Bloccati</h2>
    <div v-if="blockedUsers.length === 0" class="alert alert-info" role="alert">
      Nessun utente bloccato.
    </div>
    <ul v-else class="list-group">
      <li v-for="user in blockedUsers" :key="user.id" class="list-group-item d-flex justify-content-between align-items-center">
        {{ user.nome }} {{ user.cognome }}
        <button @click="toggleBlock(user.id)" class="btn btn-sm btn-success">Sblocca</button>
      </li>
    </ul>

    <!-- Report Modal -->
    <div class="modal fade" id="reportModal" tabindex="-1" aria-labelledby="reportModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="reportModalLabel">Segnala Utente</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <textarea v-model="reportReason" class="form-control" rows="3" placeholder="Motivo della segnalazione (opzionale)"></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annulla</button>
            <button type="button" class="btn btn-primary" @click="submitReport">Invia Segnalazione</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from "@/utils/config";
import {Modal} from 'bootstrap';

export default {
  name: 'ListaInterazioni',
  data() {
    return {
      interazioni: [],
      reportedUsers: new Set(),
      blockedUsers: [],
      reportReason: '',
      currentInteractionId: null,
      currentReportedUserId: null
    };
  },
  mounted() {
    this.fetchInterazioni();
    this.fetchBlockedUsers();
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  methods: {
    async fetchInterazioni() {
      try {
        const response = await axios.get(`${config.baseUrl}/interazione/recent/${this.currentUser.id}`);
        this.interazioni = response.data;
        this.updateReportedUsers();
      } catch (error) {
        console.error('Error fetching interazioni:', error);
      }
    },
    async fetchBlockedUsers() {
      try {
        const response = await axios.get(`${config.baseUrl}/utenteBloccaUtente/bloccati/${this.currentUser.id}`);
        this.blockedUsers = response.data;
      } catch (error) {
        console.error('Error fetching blocked users:', error);
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString('it-IT', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    openReportModal(interazioneId, segnalatoId) {
      this.currentInteractionId = interazioneId;
      this.currentReportedUserId = segnalatoId;
      this.reportReason = '';
      const modal = new Modal(document.getElementById('reportModal'));
      modal.show();
    },
    async submitReport() {
      try {
        await axios.post(`${config.baseUrl}/segnalazione`, {
          interazioneId: this.currentInteractionId,
          segnalatoId: this.currentReportedUserId,
          segnalatoreId: this.currentUser.id,
          commento: this.reportReason
        });
        this.reportedUsers.add(`${this.currentInteractionId}-${this.currentReportedUserId}`);
        const modal = Modal.getInstance(document.getElementById('reportModal'));
        modal.hide();
        await this.fetchInterazioni();
      } catch (error) {
        console.error('Error reporting user:', error);
      }
    },
    isReported(interazioneId, userId) {
      return this.reportedUsers.has(`${interazioneId}-${userId}`);
    },
    updateReportedUsers() {
      this.reportedUsers.clear();
      this.interazioni.forEach(interazione => {
        interazione.segnalazioni.forEach(segnalazione => {
          if (segnalazione.segnalatore.id === this.currentUser.id) {
            this.reportedUsers.add(`${interazione.id}-${segnalazione.segnalato.id}`);
          }
        });
      });
    },
    isBlocked(userId) {
      return this.blockedUsers.some(user => user.id === userId);
    },
    async toggleBlock(userId) {
      try {
        if (this.isBlocked(userId)) {
          await axios.delete(`${config.baseUrl}/utenteBloccaUtente/${this.currentUser.id}/${userId}`);
        } else {
          await axios.post(`${config.baseUrl}/utenteBloccaUtente`, {
            bloccanteId: this.currentUser.id,
            bloccatoId: userId
          });
        }
        await this.fetchBlockedUsers();
        await this.fetchInterazioni();
      } catch (error) {
        console.error('Error toggling block status:', error);
      }
    }
  }
};
</script>
<style scoped>
.littlemargin {
  margin-left: 5px;
}
.btn{
  padding: 2px;
}
</style>
