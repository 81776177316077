<!-- ModalGestisciModifica.vue -->
<template>
  <div>
    <!-- Main Modal -->
    <div class="modal fade" id="gestisciModifica" tabindex="-1" aria-labelledby="gestisciModificaLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="gestisciModificaLabel">
              {{ isEditing ? 'Modifica disponibilità' : 'Gestisci disponibilità' }}
            </h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal-edit"></button>
          </div>
          <div class="modal-body">
            <div v-if="selectedDisponibilita">
              <form v-if="isEditing" @submit.prevent="handleSave" class="mt-3">
                <strong>Data:</strong> {{ formatLocalDate(localDisponibilita.dataInizio) }}<br>
                <div class="form-group">
                  <label for="oraInizioEdit">Ora Inizio:</label>
                  <input type="time" v-model="localDisponibilita.oraInizio" id="oraInizioEdit"
                         class="form-control" autocomplete="off" required>
                </div>
                <div class="form-group">
                  <label for="oraFineEdit">Ora Fine:</label>
                  <input type="time" v-model="localDisponibilita.oraFine" id="oraFineEdit"
                         class="form-control" autocomplete="off" required>
                </div>
                <div class="form-group">
                  <label for="maxNumPartecipantiEdit">Massimo numero di partecipanti (facoltativo):</label>
                  <input type="number" v-model="localDisponibilita.maxPartecipantiGruppo"
                         id="maxNumPartecipantiEdit" class="form-control" autocomplete="off">
                </div>
                <strong>Ricorrente:</strong> {{ formatBoolean(localDisponibilita.ricorrente) }}
              </form>
              <div v-else>
                <p>
                  <strong>Data:</strong> {{ formatLocalDate(selectedDisponibilita.dataInizio) }}<br>
                  <strong>Ora Inizio:</strong> {{ formatTime(selectedDisponibilita.oraInizio) }}<br>
                  <strong>Ora Fine:</strong> {{ formatTime(selectedDisponibilita.oraFine) }}<br>
                  <strong>Max Partecipanti:</strong>
                  {{ formatMaxPartecipanti(selectedDisponibilita.maxPartecipantiGruppo) }}<br>
                  <strong>Ricorrente:</strong> {{ formatBoolean(selectedDisponibilita.ricorrente) }}<br>
                </p>
                <div v-if="selectedDisponibilita.ricorrente">
                  <strong>Disponibilità associate:</strong>
                  <div v-if="parentDisponibilita">
                    <p style="margin-bottom: 1px">• {{ formatWeekDay(parentDisponibilita.giornoSettimana)}} {{ formatLocalDate(parentDisponibilita.dataInizio) }}</p>
                  </div>
                  <div v-if="childDisponibilita.length">
                    <p style="margin-bottom: 1px" v-for="child in visibleChildren" :key="child.id">• {{ formatWeekDay(child.giornoSettimana)}} {{ formatLocalDate(child.dataInizio) }}</p>
                  </div>
                  <button v-if="childDisponibilita.length > shownItems" @click="showMore" class="btn btn-secondary btn-sm mt-2">
                    Mostra di più
                  </button>
                  <div v-if="!childDisponibilita.length && !parentDisponibilita">
                    <p>Nessuna disponibilità padre o figli.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="!isEditing" @click="startEditing" class="btn btn-primary btn-sm">Modifica</button>
            <button v-if="isEditing" @click="handleSave" class="btn btn-success btn-sm custom-btn">Salva</button>
            <button v-if="!isEditing" @click="showDeleteModal" class="btn btn-danger btn-sm">Elimina</button>
            <button v-if="isEditing" @click="cancelEditing" class="btn btn-danger btn-sm custom-btn">Annulla</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Future Disponibilità Modal -->
    <div class="modal fade" id="editFutureDisp" tabindex="-1" aria-labelledby="editFutureDispLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="editFutureDispLabel">Conferma modifica</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal-edit"></button>
          </div>
          <div class="modal-body">
            <strong>Attenzione!</strong><br>
            Modificare anche le occorrenze future di questa disponibilità?
          </div>
          <div class="modal-footer">
            <div id="loadingSpinnerEdit" style="display: none" class="spinner-border text-muted"></div>
            <button @click="modifyDisponibilitaRicorrente" class="btn btn-danger custom-btn" id="editDispButton">Si</button>
            <button @click="modifyDisponibilita" class="btn btn-secondary custom-btn" id="editDispButton">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from 'bootstrap';
export default {
  name: 'ModalGestisciModifica',
  props: {
    selectedDisponibilita: Object,
    parentDisponibilita: Object,
    childDisponibilita: Array,
    shownItems: Number
  },
  data() {
    return {
      isEditing: false,
      localDisponibilita: null
    }
  },
  computed: {
    visibleChildren() {
      return this.childDisponibilita.slice(0, this.shownItems);
    }
  },
  watch: {
    selectedDisponibilita: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.localDisponibilita = JSON.parse(JSON.stringify(newVal));
        }
      }
    }
  },
  methods: {
    formatLocalDate(localDate) {
      const date = new Date(localDate);
      return date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' });
    },
    formatTime(timeString) {
      return timeString.slice(0, 5);
    },
    formatMaxPartecipanti(value) {
      return value === null || value === undefined ? "Nessun limite" : value;
    },
    formatBoolean(value) {
      return value ? 'Si' : 'No';
    },
    formatWeekDay(value) {
      const days = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
      return days[value] || '';
    },
    showMore() {
      this.$emit('show-more');
    },
    startEditing() {
      this.isEditing = true;
    },
    cancelEditing() {
      this.isEditing = false;
      this.localDisponibilita = JSON.parse(JSON.stringify(this.selectedDisponibilita));
    },
    handleSave() {
      if (this.localDisponibilita.ricorrente) {
        this.openEditFutureDispModal();
      } else {
        this.modifyDisponibilita();
      }
    },
    modifyDisponibilita() {
      this.$emit('modify-disponibilita', this.localDisponibilita);
      this.closeModal();
    },
    modifyDisponibilitaRicorrente() {
      this.$emit('modify-disponibilita-ricorrente', this.localDisponibilita);
      this.closeEditFutureDispModal();
    },
    showDeleteModal() {
      this.$emit('show-modal-delete');
    },
    openEditFutureDispModal() {
      const gestisciModificaModal = document.getElementById('gestisciModifica');
      const editFutureDispModal = document.getElementById('editFutureDisp');

      if (gestisciModificaModal) {
        gestisciModificaModal.classList.remove('show');
        gestisciModificaModal.style.display = 'none';
      }

      if (editFutureDispModal) {
        editFutureDispModal.classList.add('show');
        editFutureDispModal.style.display = 'block';
      }
    },
    closeEditFutureDispModal() {
      const editFutureDispModal = document.getElementById('editFutureDisp');
      if (editFutureDispModal) {
        editFutureDispModal.classList.remove('show');
        editFutureDispModal.style.display = 'none';
      }
    },
    closeModal() {
      const modalElement = document.getElementById('gestisciModifica');
      if (modalElement) {
        const modalInstance = Modal.getInstance(modalElement);
        if (modalInstance) {
          modalInstance.hide();
        }
      }
      this.isEditing = false;
    },
  }
}
</script>
